import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  content: [],
  hashtags: [],
  currentContent: null,
};

// Create Content
const createContentStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const createContentSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const createContentFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch hashtags
const fetchHashtagsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchHashtagsSuccess = (state, action) => {
  return {
    ...state,
    hashtags: action.hashtags,
    loading: false,
    error: false,
  };
};

const fetchHashtagsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch All Content
const fetchAllContentStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchAllContentSuccess = (state, action) => {
  return {
    ...state,
    content: action.content,
    loading: false,
    error: false,
  };
};

const fetchAllContentFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Content by id
const fetchContentByIdStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchContentByIdSuccess = (state, action) => {
  return {
    ...state,
    currentContent: action.content,
    loading: false,
    error: false,
  };
};

const fetchContentByIdFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Delete Content
const deleteContentStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const deleteContentSuccess = (state, action) => {
  const updatedItems = {
    ...state.content,
    items: state.content.items.filter((item) => item.id !== action.contentId),
  };

  return {
    ...state,
    content: updatedItems,
    loading: false,
    error: false,
  };
};

const deleteContentFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Update Content
const updateContentStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const updateContentSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const updateContentFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Disrutpor
const fetchDisruptorStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchDisruptorSuccess = (state, action) => {
  return {
    ...state,
    content: action.content,
    loading: false,
    error: false,
  };
};

const fetchDisruptorFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Content by id
const deleteCommentByIdStart = (state) => {
  return {
    ...state,
    loading: true,
  };
};

const deleteCommentByIdSuccess = (state, action) => {
  return {
    ...state,
    currentContent: {
      ...state.currentContent,
      contentComments: state.currentContent.contentComments.filter(
        (e) => e.id !== action.commentId
      ),
    },
    loading: false,
    error: false,
  };
};

const deleteCommentByIdFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTENT_START:
      return createContentStart(state, action);
    case actionTypes.CREATE_CONTENT_SUCCESS:
      return createContentSuccess(state, action);
    case actionTypes.CREATE_CONTENT_FAIL:
      return createContentFail(state, action);
    case actionTypes.FETCH_HASHTAGS_START:
      return fetchHashtagsStart(state, action);
    case actionTypes.FETCH_HASHTAGS_SUCCESS:
      return fetchHashtagsSuccess(state, action);
    case actionTypes.FETCH_HASHTAGS_FAIL:
      return fetchHashtagsFail(state, action);
    case actionTypes.FETCH_ALL_CONTENT_START:
      return fetchAllContentStart(state, action);
    case actionTypes.FETCH_ALL_CONTENT_SUCCESS:
      return fetchAllContentSuccess(state, action);
    case actionTypes.FETCH_ALL_CONTENT_FAIL:
      return fetchAllContentFail(state, action);
    case actionTypes.FETCH_CONTENT_BY_ID_START:
      return fetchContentByIdStart(state, action);
    case actionTypes.FETCH_CONTENT_BY_ID_SUCCESS:
      return fetchContentByIdSuccess(state, action);
    case actionTypes.FETCH_CONTENT_BY_ID_FAIL:
      return fetchContentByIdFail(state, action);
    case actionTypes.DELETE_CONTENT_START:
      return deleteContentStart(state, action);
    case actionTypes.DELETE_CONTENT_SUCCESS:
      return deleteContentSuccess(state, action);
    case actionTypes.DELETE_CONTENT_FAIL:
      return deleteContentFail(state, action);
    case actionTypes.UPDATE_CONTENT_START:
      return updateContentStart(state, action);
    case actionTypes.UPDATE_CONTENT_SUCCESS:
      return updateContentSuccess(state, action);
    case actionTypes.UPDATE_CONTENT_FAIL:
      return updateContentFail(state, action);
    case actionTypes.FETCH_DISRUPTOR_START:
      return fetchDisruptorStart(state, action);
    case actionTypes.FETCH_DISRUPTOR_SUCCESS:
      return fetchDisruptorSuccess(state, action);
    case actionTypes.FETCH_DISRUPTOR_FAIL:
      return fetchDisruptorFail(state, action);
    case actionTypes.DELETE_COMMENT_START:
      return deleteCommentByIdStart(state, action);
    case actionTypes.DELETE_COMMENT_SUCCESS:
      return deleteCommentByIdSuccess(state, action);
    case actionTypes.DELETE_COMMENT_FAIL:
      return deleteCommentByIdFail(state, action);

    default:
      return state;
  }
};

export default reducer;
