import { createImageBitmap } from './createImageBitmap';

export async function getImageData(blob: ImageBitmapSource, ar?: number) {
  const bitmap = await createImageBitmap(blob);
  const [width, height] = [bitmap.width, bitmap.height];
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(bitmap, 0, 0);

  if (!ar)
    return { width, height, data: ctx.getImageData(0, 0, width, height) };

  // crop
  const AR = width / height;
  if (AR > ar) {
    const nHeight = height;
    const nWidth = Math.round(nHeight * ar);
    const top = Math.round((width - nWidth) / 2);
    return {
      width: nWidth,
      height: nHeight,
      data: ctx.getImageData(top, 0, nWidth, nHeight),
    };
  }
  const nWidth = width;
  const nHeight = Math.round(nWidth / ar);
  const left = Math.round((height - nHeight) / 2);
  return {
    width: nWidth,
    height: nHeight,
    data: ctx.getImageData(0, left, nWidth, nHeight),
  };
}
