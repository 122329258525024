import EmailIcon from '@mui/icons-material/Email';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const StyledCont = styled(Box)(({ theme }) => ({
  marginTop: '51px',
}));

const StyledSortUpIcon = styled(PlayArrowIcon)(() => ({
  marginLeft: '6px',
  marginRight: '-6px',
  width: '19.65px',
  transform: 'rotate(90deg)',
}));

const StyledSortDownIcon = styled(PlayArrowIcon)(() => ({
  marginBottom: '-6px',
  width: '19.65px',

  transform: 'rotate(-90deg)',
}));

const StyledPagination = styled(Pagination)(() => ({
  '.MuiPagination-ul .Mui-selected': {
    background: '#9632FA 0% 0% no-repeat padding-box',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
    boxShadow: '0px 7px 16px #00000024',
  },
  '.MuiPagination-ul li > button': {
    margin: 0,
    height: '50px',
    width: '50px',
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  '.MuiPagination-ul li > button:not(.MuiPaginationItem-previousNext)': {
    border: 'none',
  },

  '.MuiPagination-ul': {
    display: 'inline-flex',
    position: 'relative',
    height: '50px',
    margin: '56px 0',
  },

  '.MuiPagination-ul li:first-of-type button, .MuiPagination-ul li:last-of-type button':
    {
      marginRight: '20px',
      marginLeft: '20px',
    },

  '.MuiPagination-ul li:first-of-type button:not([disabled]), .MuiPagination-ul li:last-of-type button:not([disabled])':
    {
      color: '#9632FA',
      fontSize: '20px',
    },

  '.MuiPagination-ul li:first-of-type:after': {
    content: '""',
    height: '100%',
    position: 'absolute',
    margin: 0,
    borderRadius: ' 8px',
    border: '1px solid #CACACA',
    left: '90px',
    right: '90px',
  },

  '.MuiPaginationItem-icon': {
    fontSize: '1.5rem',
  },
}));

const StyledTableRowBody = styled(TableRow)(({ theme }) => ({
  height: '90px',
  '&.Mui-selected, &.Mui-selected:hover': {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 1px 28px #0000001F',
  },
}));

function EnhancedTableHead(props) {
  const { order, orderBy, handleTableHeadCellClick } = props;

  const { t } = useTranslation();

  const headCells = [
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: t('Users.FirstName'),
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: t('Users.LastName'),
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: t('Login.EmailLabel'),
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: t('Common.Date'),
    },
    {
      id: 'subject',
      numeric: false,
      disablePadding: false,
      label: t('Contacts.Subject'),
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: t('Common.Action'),
    },
  ];

  return (
    <TableHead>
      <TableRow sx={{ height: '92px' }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
            onClick={() => {
              handleTableHeadCellClick(headCell.id);
            }}
            sx={{
              fontWeight: 'bold',
            }}
          >
            {headCell.id !== 'action' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                IconComponent={() => (
                  <>
                    <StyledSortUpIcon
                      sx={
                        orderBy === headCell.id && order === 'asc'
                          ? { opacity: '0.5' }
                          : {}
                      }
                    />
                    <StyledSortDownIcon
                      sx={
                        orderBy === headCell.id && order === 'desc'
                          ? { opacity: '0.5' }
                          : {}
                      }
                    />
                  </>
                )}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const MessagesTable = ({
  messages,
  page,
  itemsPerPage,
  setPage,
  orderBy,
  order,
  handleTableHeadCellClick,
}) => {
  const [selected, setSelected] = useState([]);

  const handleClick = (event, id) => {
    if (id === selected) {
      setSelected();
    } else {
      setSelected(id);
    }
  };

  const isSelected = (id) => selected === id;

  return (
    <StyledCont sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ minHeight: itemsPerPage * 90 + 92 + 'px' }}>
          <Table
            sx={{ minWidth: '750px' }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              rowCount={messages?.length || 0}
              handleTableHeadCellClick={handleTableHeadCellClick}
              orderBy={orderBy}
              order={order}
            />
            <TableBody>
              {messages.items &&
                messages.items.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRowBody
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                      >
                        {row.firstName}
                      </TableCell>
                      <TableCell align="left">{row.lastName}</TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="email"
                          sx={{
                            background: '#F6EEFF 0% 0% no-repeat padding-box',
                            borderRadius: '13px',
                            mr: '11px',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(
                              `mailto:${row.email}?subject=${row.subject}&body=`
                            );
                          }}
                        >
                          <EmailIcon color="primary" />
                        </IconButton>
                        {row.email}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                      >
                        {new Date(row.timeStamp).toLocaleString(
                          navigator.language
                        )}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                      >
                        {row.subject}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          component={Link}
                          to={`/union-settings/contact-messages/${row.id}`}
                          aria-label="view"
                          sx={{
                            background: 'transparent',
                            border: '1px solid #CACACA',
                            borderRadius: '5px',
                            mr: '10px',
                          }}
                        >
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    </StyledTableRowBody>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {messages.items && messages.items.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <StyledPagination
              shape="rounded"
              variant="outlined"
              onChange={(e, selectedPage) => {
                setPage(selectedPage);
              }}
              page={page}
              count={messages.totalPages}
            />
            <Box sx={{ marginRight: '20px' }}>
              Datensätze
              <b>
                {` ${page * itemsPerPage - itemsPerPage + 1} - ${
                  page * itemsPerPage < messages.totalCount
                    ? page * itemsPerPage
                    : messages.totalCount
                } `}
              </b>
              von <b>{messages.totalPages}</b>
            </Box>
          </Box>
        )}
      </Paper>
    </StyledCont>
  );
};

export default MessagesTable;
