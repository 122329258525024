export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';

export const CLEAR_ERROR = 'CLEAR_ERROR';

// Permissions
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const ADD_ROLE_START = 'ADD_ROLE_START';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL';

export const FETCH_CROSS_SECTIONAL_ORGANIZATIONS_START =
  'FETCH_CROSS_SECTIONAL_ORGANIZATIONS_START';
export const FETCH_CROSS_SECTIONAL_ORGANIZATIONS_SUCCESS =
  'FETCH_CROSS_SECTIONAL_ORGANIZATIONS_SUCCESS';
export const FETCH_CROSS_SECTIONAL_ORGANIZATIONS_FAIL =
  'FETCH_CROSS_SECTIONAL_ORGANIZATIONS_FAIL';

export const FETCH_FEDERAL_ASSOCIATIONS_START =
  'FETCH_FEDERAL_ASSOCIATIONS_START';
export const FETCH_FEDERAL_ASSOCIATIONS_SUCCESS =
  'FETCH_FEDERAL_ASSOCIATIONS_SUCCESS';
export const FETCH_FEDERAL_ASSOCIATIONS_FAIL =
  'FETCH_FEDERAL_ASSOCIATIONS_FAIL';

export const FETCH_FEDERAL_STATES_START = 'FETCH_FEDERAL_STATES_START';
export const FETCH_FEDERAL_STATES_SUCCESS = 'FETCH_FEDERAL_STATES_SUCCESS';
export const FETCH_FEDERAL_STATES_FAIL = 'FETCH_FEDERAL_STATES_FAIL';

export const FETCH_DISTRICT_UNIONS_START = 'FETCH_DISTRICT_UNIONS_START';
export const FETCH_DISTRICT_UNIONS_SUCCESS = 'FETCH_DISTRICT_UNIONS_SUCCESS';
export const FETCH_DISTRICT_UNIONS_FAIL = 'FETCH_DISTRICT_UNIONS_FAIL';

export const FETCH_UNIONS_START = 'FETCH_UNIONS_START';
export const FETCH_UNIONS_SUCCESS = 'FETCH_UNIONS_SUCCESS';
export const FETCH_UNIONS_FAIL = 'FETCH_UNIONS_FAIL';

export const DELETE_UNION = 'DELETE_UNION';
export const DELETE_UNION_SUCCESS = 'DELETE_UNION_SUCCESS';
export const DELETE_UNION_FAIL = 'DELETE_UNION_FAIL';

export const FETCH_ALL_UNIONS_START = 'FETCH_ALL_UNIONS_START';
export const FETCH_ALL_UNIONS_SUCCESS = 'FETCH_ALL_UNIONS_SUCCESS';
export const FETCH_ALL_UNIONS_FAIL = 'FETCH_ALL_UNIONS_FAIL';

export const FETCH_MASTER_UNIONS_START = 'FETCH_ALL_UNIONS_START';
export const FETCH_MASTER_UNIONS_SUCCESS = 'FETCH_MASTER_UNIONS_SUCCESS';
export const FETCH_MASTER_UNIONS_FAIL = 'FETCH_MASTER_UNIONS_FAIL';

export const FETCH_FEDERAL_LOCATIONS_START = 'FETCH_FEDERAL_LOCATIONS_START';
export const FETCH_FEDERAL_LOCATIONS_SUCCESS =
  'FETCH_FEDERAL_LOCATIONS_SUCCESS';
export const FETCH_FEDERAL_LOCATIONS_FAIL = 'FETCH_FEDERAL_LOCATIONS_FAIL';

export const FETCH_CROSS_ORGANIZATIONS_START =
  'FETCH_CROSS_ORGANIZATIONS_START';
export const FETCH_CROSS_ORGANIZATIONS_SUCCESS =
  'FETCH_CROSS_ORGANIZATIONS_SUCCESS';
export const FETCH_CROSS_ORGANIZATIONS_FAIL = 'FETCH_CROSS_ORGANIZATIONS_FAIL';

export const FETCH_PERMISSIONS_START = 'FETCH_PERMISSIONS_START';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL';

export const FETCH_PERMISSIONS_BY_USER_START =
  'FETCH_PERMISSIONS_BY_USER_START';
export const FETCH_PERMISSIONS_BY_USER_SUCCESS =
  'FETCH_PERMISSIONS_BY_USER_SUCCESS';
export const FETCH_PERMISSIONS_BY_USER_FAIL = 'FETCH_PERMISSIONS_BY_USER_FAIL';

export const FETCH_SYSTEM_FUNCTIONS_START = 'FETCH_SYSTEM_FUNCTIONS_START';
export const FETCH_SYSTEM_FUNCTIONS_SUCCESS = 'FETCH_SYSTEM_FUNCTIONS_SUCCESS';
export const FETCH_SYSTEM_FUNCTIONS_FAIL = 'FETCH_SYSTEM_FUNCTIONS_FAIL';

// Users
export const FETCH_USER_REQUESTS_START = 'FETCH_USER_REQUESTS_START';
export const FETCH_USER_REQUESTS_SUCCESS = 'FETCH_USER_REQUESTS_SUCCESS';
export const FETCH_USER_REQUESTS_FAIL = 'FETCH_USER_REQUESTS_FAIL';

export const FETCH_COCKPIT_USERS_START = 'FETCH_COCKPIT_USERS_START';
export const FETCH_COCKPIT_USERS_SUCCESS = 'FETCH_COCKPIT_USERS_SUCCESS';
export const FETCH_COCKPIT_USERS_FAIL = 'FETCH_COCKPIT_USERS_FAIL';

export const FETCH_USER_START = 'FETCH_USER_START';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';

export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export const APPROVE_USER_START = 'APPROVE_USER_START';
export const APPROVE_USER_SUCCESS = 'APPROVE_USER_SUCCESS';
export const APPROVE_USER_FAIL = 'APPROVE_USER_FAIL';

export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';

export const REQUEST_PASSWORD_CHANGE_START = 'REQUEST_PASSWORD_CHANGE_START';
export const REQUEST_PASSWORD_CHANGE_SUCCESS =
  'REQUEST_PASSWORD_CHANGE_SUCCESS';
export const REQUEST_PASSWORD_CHANGE_FAIL = 'REQUEST_PASSWORD_CHANGE_FAIL';

export const SET_SNACKBAR = 'SET_SNACKBAR';

export const SET_CHECK_EMAIL = 'SET_CHECK_EMAIL';

// Unions
export const CREATE_UNION_START = 'CREATE_UNION_START';
export const CREATE_UNION_SUCCESS = 'CREATE_UNION_SUCCESS';
export const CREATE_UNION_FAIL = 'CREATE_UNION_FAIL';

export const EDIT_UNION_START = 'EDIT_UNION_START';
export const EDIT_UNION_SUCCESS = 'EDIT_UNION_SUCCESS';
export const EDIT_UNION_FAIL = 'EDIT_UNION_FAIL';

export const GET_UNION_START = 'GET_UNION_START';
export const GET_UNION_SUCCESS = 'GET_UNION_SUCCESS';
export const GET_UNION_FAIL = 'GET_UNION_FAIL';

export const PUT_UNION_RSS_START = 'PUT_UNION_RSS_START';
export const PUT_UNION_RSS_SUCCESS = 'PUT_UNION_RSS_SUCCESS';
export const PUT_UNION_RSS_FAIL = 'PUT_UNION_RSS_FAIL';

export const GET_UNION_RSS_START = 'GET_UNION_RSS_START';
export const GET_UNION_RSS_SUCCESS = 'GET_UNION_RSS_SUCCESS';
export const GET_UNION_RSS_FAIL = 'GET_UNION_RSS_FAIL';

export const FETCH_DROPDOWN_UNIONS_START = 'FETCH_DROPDOWN_UNIONS_START';
export const FETCH_DROPDOWN_UNIONS_SUCCESS = 'FETCH_DROPDOWN_UNIONS_SUCCESS';
export const FETCH_DROPDOWN_UNIONS_FAIL = 'FETCH_DROPDOWN_UNIONS_FAIL';

// Fetch Current User
export const FETCH_CURRENT_USER_START = 'FETCH_CURRENT_USER_START';
export const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS';
export const FETCH_CURRENT_USER_FAIL = 'FETCH_CURRENT_USER_FAIL';

// Content
export const CREATE_CONTENT_START = 'CREATE_CONTENT_START';
export const CREATE_CONTENT_SUCCESS = 'CREATE_CONTENT_SUCCESS';
export const CREATE_CONTENT_FAIL = 'CREATE_CONTENT_FAIL';

export const FETCH_HASHTAGS_START = 'FETCH_HASHTAGS_START';
export const FETCH_HASHTAGS_SUCCESS = 'FETCH_HASHTAGS_SUCCESS';
export const FETCH_HASHTAGS_FAIL = 'FETCH_HASHTAGS_FAIL';

export const FETCH_ALL_CONTENT_START = 'FETCH_ALL_CONTENT_START';
export const FETCH_ALL_CONTENT_SUCCESS = 'FETCH_ALL_CONTENT_SUCCESS';
export const FETCH_ALL_CONTENT_FAIL = 'FETCH_ALL_CONTENT_FAIL';

export const FETCH_CONTENT_BY_ID_START = 'FETCH_CONTENT_BY_ID_START';
export const FETCH_CONTENT_BY_ID_SUCCESS = 'FETCH_CONTENT_BY_ID_SUCCESS';
export const FETCH_CONTENT_BY_ID_FAIL = 'FETCH_CONTENT_BY_ID_FAIL';

export const DELETE_CONTENT_START = 'DELETE_CONTENT_START';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAIL = 'DELETE_CONTENT_FAIL';

export const UPDATE_CONTENT_START = 'UPDATE_CONTENT_START';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAIL = 'UPDATE_CONTENT_FAIL';

export const FETCH_UNIONS_BY_MASTER_UNION_ID_START =
  'FETCH_UNIONS_BY_MASTER_UNION_ID_START';
export const FETCH_UNIONS_BY_MASTER_UNION_ID_SUCCESS =
  'FETCH_UNIONS_BY_MASTER_UNION_ID_SUCCESS';
export const FETCH_UNIONS_BY_MASTER_UNION_ID_FAIL =
  'FETCH_UNIONS_BY_MASTER_UNION_ID_FAIL';

export const SELECT_UNION_SUCCESS = 'SELECT_UNION_SUCCESS';
export const SELECT_UNIONS_GROUPED_BY_MASTER_SUCCESS =
  'SELECT_UNIONS_GROUPED_BY_MASTER_SUCCESS';

export const UPDATE_ROLE_PERMISSIONS_START = 'UPDATE_ROLE_PERMISSIONS_START';
export const UPDATE_ROLE_PERMISSIONS_SUCCESS =
  'UPDATE_ROLE_PERMISSIONS_SUCCESS';
export const UPDATE_ROLE_PERMISSIONS_FAIL = 'UPDATE_ROLE_PERMISSIONS_FAIL';

export const UPDATE_COCKPIT_USER_START = 'UPDATE_COCKPIT_USER_START';
export const UPDATE_COCKPIT_USER_SUCCESS = 'UPDATE_COCKPIT_USER_SUCCESS';
export const UPDATE_COCKPIT_USER_FAIL = 'UPDATE_COCKPIT_USER_FAIL';

export const FETCH_DISRUPTOR_START = 'FETCH_DISRUPTOR_START';
export const FETCH_DISRUPTOR_SUCCESS = 'FETCH_DISRUPTOR_SUCCESS';
export const FETCH_DISRUPTOR_FAIL = 'FETCH_DISRUPTOR_FAIL';

export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';

export const FETCH_STATE_UNIONS_START = 'FETCH_STATE_UNIONS_START';
export const FETCH_STATE_UNIONS_SUCCESS = 'FETCH_STATE_UNIONS_SUCCESS';
export const FETCH_STATE_UNIONS_FAIL = 'FETCH_STATE_UNIONS_FAIL';
