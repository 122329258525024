import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  users: [],
  userRequests: [],
  approvedUsers: [],
  cockpitUsers: [],
  selectedUser: null,
};

// Fetch User Requests
const fetchUserRequestsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchUserRequestsSuccess = (state, action) => {
  return {
    ...state,
    approvedUsers:
      action.userRequests.items.length > 0 &&
      (action.userRequests.items[0].isApproved === 'Approved' ||
        action.userRequests.items[0].isApproved === 'Rejected')
        ? action.userRequests
        : [],

    userRequests:
      action.userRequests.items.length > 0 &&
      (action.userRequests.items[0].isApproved === 'Pending'
        ? action.userRequests
        : []),
    loading: false,
    error: false,
  };
};

const fetchUserRequestsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

//GET COCKPIT USERS

const getCockpitUsersStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const getCockpitUsersSuccess = (state, action) => {
  return {
    ...state,
    cockpitUsers: action.cockpitUsers,
    loading: false,
    error: false,
  };
};

const getCockpitUsersFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch User
const fetchUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
    selectedUser: null,
  };
};

const fetchUserSuccess = (state, action) => {
  return {
    ...state,
    selectedUser: action.selectedUser,
    loading: false,
    error: false,
  };
};

const fetchUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    selectedUser: null,
  };
};

// EDit User
const editUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const editUserSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const editUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Approve User
const approveUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const approveUserSuccess = (state, action) => {
  return {
    ...state,
    userRequests: state.userRequests.filter(
      (item) => item.id !== action.userId
    ),
    loading: false,
    error: false,
  };
};

const approveUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Delete User
const deleteUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const deleteUserSuccess = (state, action) => {
  return {
    ...state,
    approvedUsers: state.approvedUsers.filter(
      (item) => item.id !== action.userId
    ),
    cockpitUsers: state.cockpitUsers.filter(
      (item) => item.id !== action.userId
    ),
    loading: false,
    error: false,
  };
};

const deleteUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Update Cokcpit user
const updateCockpitUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const updateCockpitUserSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const updateCockpitUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_REQUESTS_START:
      return fetchUserRequestsStart(state, action);
    case actionTypes.FETCH_USER_REQUESTS_SUCCESS:
      return fetchUserRequestsSuccess(state, action);
    case actionTypes.FETCH_USER_REQUESTS_FAIL:
      return fetchUserRequestsFail(state, action);
    case actionTypes.FETCH_COCKPIT_USERS_START:
      return getCockpitUsersStart(state, action);
    case actionTypes.FETCH_COCKPIT_USERS_SUCCESS:
      return getCockpitUsersSuccess(state, action);
    case actionTypes.FETCH_COCKPIT_USERS_FAIL:
      return getCockpitUsersFail(state, action);
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state, action);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.EDIT_USER_START:
      return editUserStart(state, action);
    case actionTypes.EDIT_USER_SUCCESS:
      return editUserSuccess(state, action);
    case actionTypes.EDIT_USER_FAIL:
      return editUserFail(state, action);
    case actionTypes.APPROVE_USER_START:
      return approveUserStart(state, action);
    case actionTypes.APPROVE_USER_SUCCESS:
      return approveUserSuccess(state, action);
    case actionTypes.APPROVE_USER_FAIL:
      return approveUserFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.UPDATE_COCKPIT_USER_START:
      return updateCockpitUserStart(state, action);
    case actionTypes.UPDATE_COCKPIT_USER_SUCCESS:
      return updateCockpitUserSuccess(state, action);
    case actionTypes.UPDATE_COCKPIT_USER_FAIL:
      return updateCockpitUserFail(state, action);
    default:
      return state;
  }
};

export default reducer;
