import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { ContactMessages } from 'containers/Contacts/ContactMessages';
import { isSuperAdmin } from 'helpers/isSuperAdmin';
import { useAppDispatch } from 'hooks/useRedux';
import { AdvertismentsForm } from 'modules/AdvertismentsForm';
import { AdvertismentsTable } from 'modules/AdvertismentsTable';
import { ArticlesForm } from 'modules/ArticleForm';
import { ArticlesTable } from 'modules/ArticlesTable';
import { CommitteesTable } from 'modules/CommitteesTable';
import { DisruptorsTable } from 'modules/DisruptorsTable';
import { DownloadsForm } from 'modules/DownloadsForm';
import { DownloadsTable } from 'modules/DownloadsTable';
import { NotificationsDetails } from 'modules/NotificationsDetails';
import { NotificationsForm } from 'modules/NotificationsForm';
import { NotificationsIndependentTable } from 'modules/NotificationsIndependentTable';
import { NotificationsTable } from 'modules/NotificationsTable';
import { RssFeedsTable } from 'modules/RssFeedsTable';
import { UserDetails } from 'modules/UserDetails';
import { UsersRequestTable } from 'modules/UsersRequestTable';
import { UsersTable } from 'modules/UsersTable';
import { Suspense, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { routes } from 'routes';
import {
  fetchDropdownUnions,
  fetchUnionList,
} from 'store/actions/unionActions';
import { CreateCommittee } from './containers/Committees/CreateCommittee';
import { CreateContact } from './containers/Contacts/CreateContact';
import { Sidebar } from './modules/Sidebar';
import { UnionSocials } from './modules/UnionSocials';
import routeConstants from './shared/constants/routeConstants';
import {
  CockpitUserDetails,
  CockpitUsers,
  ContactMessage,
  ContentDetails,
  ContentEdit,
  Contents,
  ContentWizard,
  CreateNewUnion,
  CreateNewUser,
  Home,
  NotFound,
  UnionEmails,
  UnionList,
  UnionRss,
} from './shared/lazy';

const {
  CREATE_NEW_USER,
  COCKPIT_USERS,
  COCKPIT_USER_DETAILS,
  // COCKPIT_USER_PERMISSIONS,
  CHECK_EMAIL,
  CREATE_NEW_UNION,
  UNION_LIST,
  CONFIRM_EMAIL,
  CONTENT_WIZARD,
  CONTENTS,
  CONTENTS_TOPICS,
  CONTENTS_NEWS,
  CONTENTS_RSS,
  CONTENTS_DISRUPTOR,
  CONTENT_DETAILS,
  CONTENT_EDIT,
  VALIDATE_CODE,
  UNION_EMAILS,
  UNION_RSS,
  UNION_FOOTER,
  CONTACT_MESSAGE,
  CREATE_CONTACT,
  CONTACT_MESSAGES,
  CREATE_COMMITTEE,
  EDIT_COMMITTEE,
} = routeConstants;

const StyledRoot = styled(Box)(({ theme }) => ({ display: 'flex' }));

export function AppAuthorized({ user }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUnionList());
    dispatch(fetchDropdownUnions());
  }, [dispatch]);

  return (
    <StyledRoot>
      <Sidebar user={user} />
      <Suspense fallback={null}>
        <Routes>
          <Route
            element={isSuperAdmin(user) ? <Outlet /> : <Navigate to="/" />}
          >
            <Route path={CREATE_NEW_USER.PATH} element={<CreateNewUser />} />
            <Route
              path={`${CREATE_NEW_USER.PATH}/:userId`}
              element={<CreateNewUser />}
            />
            <Route path={CREATE_NEW_UNION.PATH} element={<CreateNewUnion />} />
            <Route
              path={`${CREATE_NEW_UNION.PATH}/:unionId`}
              element={<CreateNewUnion />}
            />
            <Route path={COCKPIT_USERS.PATH} element={<CockpitUsers />} />
            <Route
              path={COCKPIT_USER_DETAILS.PATH}
              element={<CockpitUserDetails />}
            />
            {/* <Route
              path={COCKPIT_USER_PERMISSIONS.PATH}
              element={<CockpitUserPermissions />}
            /> */}
            <Route path={UNION_LIST.PATH} element={<UnionList />} />
            <Route
              path={routes.ADVERTISMENTS}
              element={<AdvertismentsTable />}
            />
            <Route
              path={routes.ADVERTISMENTS_CREATE}
              element={<AdvertismentsForm />}
            />
            <Route
              path={routes.ADVERTISMENTS_EDIT}
              element={<AdvertismentsForm />}
            />
            <Route
              path={routes.ADVERTISMENTS}
              element={<AdvertismentsTable />}
            />
            <Route path={routes.DISRUPTORS} element={<DisruptorsTable />} />
            <Route path={routes.RSS} element={<RssFeedsTable />} />
            <Route
              path={routes.NOTIFICATIONS_APROVE_LIST}
              element={<NotificationsTable />}
            />
            <Route
              path={routes.NOTIFICATIONS_APROVE}
              element={<NotificationsForm />}
            />
          </Route>

          <Route path={routes.NEWS} element={<ArticlesTable type="news" />} />
          <Route path={routes.NEWS_CREATE} element={<ArticlesForm />} />
          <Route path={routes.NEWS_EDIT} element={<ArticlesForm />} />
          <Route
            path={routes.TOPICS}
            element={<ArticlesTable type="topic" />}
          />
          <Route path={routes.TOPICS_CREATE} element={<ArticlesForm />} />
          <Route path={routes.TOPICS_EDIT} element={<ArticlesForm />} />

          <Route
            path={routes.NOTIFICATIONS}
            element={<NotificationsIndependentTable />}
          />
          <Route
            path={routes.NOTIFICATIONS_CREATE}
            element={<NotificationsForm />}
          />
          <Route
            path={routes.NOTIFICATIONS_DETAILS}
            element={<NotificationsDetails />}
          />

          <Route
            path={routes.NOTIFICATIONS_EDIT}
            element={<NotificationsForm />}
          />

          <Route path={routes.DOWNLOADS} element={<DownloadsTable />} />
          <Route path={routes.DOWNLOADS_CREATE} element={<DownloadsForm />} />

          <Route path={routes.COMMITTEES} element={<CommitteesTable />} />

          <Route path={CREATE_COMMITTEE.PATH} element={<CreateCommittee />} />
          <Route path={EDIT_COMMITTEE.PATH} element={<CreateCommittee />} />

          <Route path={routes.USERS} element={<UsersTable />} />
          <Route path={routes.USERS_REQUESTS} element={<UsersRequestTable />} />
          <Route path={routes.USERS_DETAILS} element={<UserDetails />}></Route>
          <Route path={CONTENT_WIZARD.PATH} element={<ContentWizard />}></Route>
          <Route
            path={CONTENTS_TOPICS.PATH}
            element={<ArticlesTable type="news" />}
          />
          <Route
            path={CONTENTS_NEWS.PATH}
            element={<ArticlesTable type="topic" />}
          />
          <Route path={CONTENTS.PATH} element={<Contents />}>
            <Route path={CONTENTS_RSS.PATH} element={<Contents />} />
            <Route path={CONTENTS_DISRUPTOR.PATH} element={<Contents />} />
          </Route>

          {/* //TODO: fix to new routes, make new preview */}
          <Route path={CONTENT_DETAILS.PATH} element={<ContentDetails />} />
          <Route path={CONTENT_EDIT.PATH} element={<ContentEdit />} />
          <Route path={UNION_EMAILS.PATH} element={<UnionEmails />} />
          <Route path={UNION_RSS.PATH} element={<UnionRss />} />
          <Route path={UNION_FOOTER.PATH} element={<UnionSocials />} />
          <Route path={CONTACT_MESSAGES.PATH} element={<ContactMessages />} />
          <Route path={CONTACT_MESSAGE.PATH} element={<ContactMessage />} />
          <Route path={CREATE_CONTACT.PATH} element={<CreateContact />} />

          <Route path={CHECK_EMAIL.PATH} element={<Navigate to="/" />} />
          <Route path={VALIDATE_CODE.PATH} element={<Navigate to="/" />} />
          <Route path={CONFIRM_EMAIL.PATH} element={<Navigate to="/" />} />

          <Route path="/test" element={<CreateCommittee />} />

          <Route path="/" element={<Home />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </StyledRoot>
  );
}
