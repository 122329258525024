import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useOnBeforeUnload() {
  const { t } = useTranslation();

  const unloadCallback = useCallback<NonNullable<OnBeforeUnloadEventHandler>>(
    (event) => {
      event.preventDefault();
      event.returnValue = t('confirmLeaveSite');
      return t('confirmLeaveSite');
    },
    [t]
  );

  const clearUnloadCallback = useCallback(
    () => window.removeEventListener('beforeunload', unloadCallback),
    [unloadCallback]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', unloadCallback);
    return clearUnloadCallback;
  }, [unloadCallback, clearUnloadCallback]);

  return clearUnloadCallback;
}
