import { Backdrop, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledCenter = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  width: '100%',
  height: '100vh',
}));

const LoadingSpinner = () => {
  return (
    <StyledCenter>
      <Backdrop
        open={true}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    </StyledCenter>
  );
};

export default LoadingSpinner;
