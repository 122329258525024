import { api } from './api';

export const apiCommittees = api
  .injectEndpoints({
    endpoints: (build) => ({
      postApiV1CreateCommitteesMember: build.mutation({
        query: ({ data }) => ({
          url: `/api/v1/UnionBoardMember/CreateUnionBoardMember`,
          method: 'POST',
          data,
        }),
      }),
      getApiV1CommitteeMemberById: build.query({
        query: ({ id }) => ({
          url: `/api/v1/UnionBoardMember/GetUnionBoardMemberById/${id}`,
        }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      getApiV3CommitteesByUnionId: build.query<any, any>({
        query: ({
          unionId,
          page,
          itemsPerPage,
          orderBy,
          order,
          globalFilter,
        }) => ({
          url: `/api/v3/committees/all`,
          params: {
            unionId: unionId,
            PageIndex: page,
            PageSize: itemsPerPage,
            SortColumn: orderBy,
            SortOrder: order,
            FilterValue: globalFilter,
          },
        }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      getApiV1UnionMemberCategories: build.query({
        query: () => ({
          url: `/api/v1/UnionMemberCategory/GetUnionMemberCategories`,
        }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      putApiV2CommitteesMember: build.mutation({
        query: ({ data }) => ({
          url: `/api/v1/UnionBoardMember/UpdateUnionBoardMember`,
          method: 'PUT',
          data,
        }),
      }),
      deleteApiV1CommitteeMember: build.mutation({
        query: ({ id }) => ({
          url: `/api/v1/UnionBoardMember/DeleteUnionBoardMember/${id}`,
          method: 'DELETE',
        }),
      }),
      putApiV1CommitteeMemberSort: build.mutation({
        query: ({ data }) => ({
          url: `/api/v1/UnionBoardMember/UpdateUnionBoardMemberSortOrder`,
          method: 'PUT',
          data,
        }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      putApiV1CommitteeMembersOrder: build.mutation({
        query: ({ from, to }) => ({
          url: `/api/v3/committees/order?from=${from}&to=${to}`,
          method: 'PUT',
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ['Committees'],
    endpoints: {
      getApiV3CommitteesByUnionId: {
        providesTags: ['Committees'],
      },
      getApiV1CommitteeMemberById: {
        providesTags: ['Committees'],
      },
      postApiV1CreateCommitteesMember: {
        invalidatesTags: (_, error) =>
          error !== undefined ? [] : ['Committees'],
      },
      putApiV2CommitteesMember: {
        invalidatesTags: (_, error) =>
          error !== undefined ? [] : ['Committees'],
      },
      deleteApiV1CommitteeMember: {
        invalidatesTags: (_, error) =>
          error !== undefined ? [] : ['Committees'],
      },
      putApiV1CommitteeMemberSort: {
        invalidatesTags: (_, error) =>
          error !== undefined ? [] : ['Committees'],
      },
      putApiV1CommitteeMembersOrder: {
        invalidatesTags: (_, error) =>
          error !== undefined ? [] : ['Committees'],
      },
    },
  });

export const {
  usePostApiV1CreateCommitteesMemberMutation,
  useGetApiV1CommitteeMemberByIdQuery,
  useGetApiV3CommitteesByUnionIdQuery,
  useGetApiV1UnionMemberCategoriesQuery,
  usePutApiV2CommitteesMemberMutation,
  useDeleteApiV1CommitteeMemberMutation,
  usePutApiV1CommitteeMemberSortMutation,
  usePutApiV1CommitteeMembersOrderMutation,
} = apiCommittees;
