import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useGetApiV1AuthQuery } from 'store/api';
import { AppAuthorized } from './AppAuthorized';
import CheckEmailPage from './containers/CheckEmailPage/CheckEmailPage';
import ConfirmEmailPage from './containers/ConfirmEmailPage/ConfirmEmailPage';
import routeConstants from './shared/constants/routeConstants';
import { Login, ValidateCodePage } from './shared/lazy';

const { CHECK_EMAIL, CONFIRM_EMAIL, VALIDATE_CODE } = routeConstants;

const StyledLogin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  width: '100%',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}));

function App() {
  const { data, isFetching, isError } = useGetApiV1AuthQuery();

  if (isFetching) return null;

  if (isError) {
    return (
      <StyledLogin>
        <Suspense fallback={null}>
          <Routes>
            <Route path={CHECK_EMAIL.PATH} element={<CheckEmailPage />} />
            <Route path={VALIDATE_CODE.PATH} element={<ValidateCodePage />} />
            <Route path={CONFIRM_EMAIL.PATH} element={<ConfirmEmailPage />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Suspense>
      </StyledLogin>
    );
  }

  const user = data?.data;

  return <AppAuthorized user={user} />;
}

export default App;
