import { yupResolver } from '@hookform/resolvers/yup';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box, Button, Card, MenuItem, Select, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import AccessControl from 'components/Shared/accessControl';
import { Avatar } from 'elements/Avatar';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  useGetApiV1ContactPersonByUnionIdQuery,
  useGetApiV1UnionByIdQuery,
  useGetApiV2TitlesQuery,
  usePostApiV1CreateContactMutation,
} from 'store/api';
import { object, string } from 'yup';
import saveIcon from '../../assets/images/save_icon.svg';
import { UploadImageDialog } from '../../components/Contacts/UploadImageDialog';
import { setSnackbar } from '../../store/actions/notificationActions';

const CreateContactShell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingInline: '50px',
  paddingBlock: '56px',
}));

const CreateContactCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  padding: '2rem',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
}));

export const CreateContact = () => {
  const unionId = useSelector((state) => state.unions.selectedUnion);
  if (!unionId) return null;
  return <CreateContactBase unionId={unionId} />;
};

export const CreateContactBase = ({ unionId }) => {
  const { data: unionValues, isFetching: isFetchingData } =
    useGetApiV1UnionByIdQuery({ unionId });

  const { data: personData, isFetching: isFetchingPersonData } =
    useGetApiV1ContactPersonByUnionIdQuery({ unionId });

  if (isFetchingData || isFetchingPersonData) return null;

  return (
    <CreateContactForm
      {...{ unionId, unionValues, personData: personData.data }}
    />
  );
};

const schema = object().shape({
  firstName: string().required(),
  name: string().notRequired(),
  street: string().required(),
  postal: string().required(),
  phone: string().required(),
  fax: string().notRequired(),
  email: string().required().email(),
});

function CreateContactForm({ unionId, unionValues, personData }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contactTitles = useGetApiV2TitlesQuery();
  const [submitContact] = usePostApiV1CreateContactMutation();

  const [image, setImage] = useState(
    personData
      ? {
          profilePicture: personData.profilePicture,
          ...personData.profilePictureMedia,
        }
      : {}
  );

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const defaultValues = {
    title: personData ? personData.unionBoardMemberTitleId : 4,
    firstName: personData ? personData.firstName : '',
    name: personData ? personData.lastName : '',
    street: personData
      ? personData.street
      : unionValues?.address?.split(',')[0],
    postal: personData
      ? personData.city?.split(',')[0]
      : unionValues.address?.split(',')[1],
    phone: personData ? personData.phoneNo : unionValues.phoneOffice,
    fax: personData ? personData.fax : unionValues.faxNumber,
    email: personData ? personData.email : unionValues.email,
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onChangeSeo = (event, type) => {
    switch (type) {
      case 'alternateText':
        setImage({ ...image, alternateText: event.target.value });
        break;
      case 'title':
        setImage({ ...image, title: event.target.value });
        break;
      case 'imageSource':
        setImage({ ...image, imageSource: event.target.value });
        break;
      case 'description':
        setImage({ ...image, description: event.target.value });
        break;
      default:
        return;
    }
  };

  const onSubmit = async (data) => {
    const values = {
      firstName: data.firstName?.trim(),
      lastName: data.name?.trim(),
      street: data.street,
      city: data.postal,
      phoneNo: data.phone,
      fax: data.fax,
      email: data.email,
      profilePicture: image.fileData || image.profilePicture ? image : null,
      unionBoardMemberTitleId: data.title,
      unionId: unionId,
    };
    submitContact(values);
    dispatch(
      setSnackbar({
        open: true,
        type: 'success',
        message: t('Contacts.CreateContactMessage'),
      })
    );
  };

  const { enqueueSnackbar } = useSnackbar();

  if (contactTitles.isLoading) return null;

  return (
    <CreateContactShell>
      <UploadImageDialog
        open={openDialog}
        onClose={handleClose}
        onImageUpload={(base64, file) => {
          const fileData = base64;
          const [name] = file.name.split('.');
          setImage({
            fileData,
            name,
            fileExtension: 'jpeg',
            alternateText: '',
            title: '',
            imageSource: '',
            description: '',
          });
          enqueueSnackbar(t('Common.ImageSuccess'), { variant: 'success' });
        }}
        image={image}
        onChangeSeo={onChangeSeo}
      />
      <Box
        sx={{ pt: '0', pb: '56px', width: '100%' }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1>{t('Menu.ContactPerson')}</h1>
        <h2>{t('Menu.DetailedView')}</h2>
        <CreateContactCard>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '75%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Wizard.Title')}</h3>
                <Controller
                  name={'title'}
                  control={control}
                  {...register('title', { required: true })}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      fullWidth
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {contactTitles.data.map(({ id, name }) => (
                        <MenuItem value={id} key={id} children={name} />
                      ))}
                    </StyledSelect>
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Users.FirstName')}</h3>
                <Controller
                  name={'firstName'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Users.FirstName')}
                      error={!!errors['firstName']}
                      helperText={errors['firstName']?.message}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Users.LastName')}</h3>
                <Controller
                  name={'name'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Users.LastName')}
                      error={!!errors['name']}
                      helperText={errors['name']?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Users.StreetAndHouseNum')}</h3>
                <Controller
                  name={'street'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Users.StreetAndHouseNum')}
                      error={!!errors['street']}
                      helperText={errors['street']?.message}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Users.ZipCodeAndCity')}</h3>
                <Controller
                  name={'postal'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Users.ZipCodeAndCity')}
                      error={!!errors['postal']}
                      helperText={errors['postal']?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Users.PhoneNumber')}</h3>
                <Controller
                  name={'phone'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Users.PhoneNumber')}
                      error={!!errors['phone']}
                      helperText={errors['phone']?.message}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Unions.FaxNumber')}</h3>
                <Controller
                  name={'fax'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Unions.FaxNumber')}
                      error={!!errors['fax']}
                      helperText={errors['fax']?.message}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Login.EmailLabel')}</h3>
                <Controller
                  name={'email'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Login.EmailLabel')}
                      error={!!errors['email']}
                      helperText={errors['email']?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '25%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <h3>{t('Contacts.ProfilePicture')}</h3>
              </Box>
              <Avatar
                photo={image.fileData || image?.profilePicture}
                onDelete={() => setImage({})}
              />
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <AccessControl
                permissionsName={['Einstellungen']}
                allowedPermissions={['edit']}
              >
                <Button
                  style={{
                    minHeight: '57px',
                    marginBottom: '4px',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  size="large"
                  variant="contained"
                  startIcon={<PhotoCamera />}
                  onClick={() => setOpenDialog(true)}
                >
                  {t('Contacts.ChangeImage')}
                </Button>
              </AccessControl>
            </Box>
          </Box>
        </CreateContactCard>
        <AccessControl
          permissionsName={['Einstellungen']}
          allowedPermissions={['edit']}
        >
          <div style={{ textAlign: 'right', marginTop: '2rem' }}>
            <Button
              sx={{
                minHeight: '60px',
                marginLeft: 'auto',
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
              size="large"
              variant="contained"
              type="submit"
            >
              <img src={saveIcon} alt="save" style={{ marginRight: '20px' }} />
              {t(`Common.SaveChanges`)}
            </Button>
          </div>
        </AccessControl>
      </Box>
    </CreateContactShell>
  );
}
