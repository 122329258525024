import { styled } from '@mui/material/styles';
import LogoSrc from './logo.svg';

export const Logo = styled((props) => (
  <img src={LogoSrc} alt="Logo" height={64} {...props} />
))(({ theme }) => ({
  margin: theme.spacing(3),
  '&:hover': {
    filter: 'drop-shadow(0px 0px 2px #ffd927AA)',
  },
}));
