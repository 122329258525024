import { api } from './api';

export const apiContacts = api
  .injectEndpoints({
    endpoints: (build) => ({
      getApiV1UnionById: build.query({
        query: ({ unionId }) => ({
          url: `/api/v1/union/GetUnionById/${unionId}`,
        }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      postApiV1CreateContact: build.mutation({
        query: (data) => ({
          url: `/api/v1/contactPerson/createContactPerson`,
          method: 'POST',
          data,
        }),
      }),
      getApiV1ContactMessagesByUnionId: build.query({
        query: ({
          unionId,
          page,
          itemsPerPage,
          orderBy,
          order,
          searchRTK,
        }) => ({
          url: `/api/v2/unions/${unionId}/contacts`,
          params: {
            PageIndex: page,
            PageSize: itemsPerPage,
            SortColumn: orderBy,
            SortOrder: order,
            FilterValue: searchRTK,
          },
        }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      getApiV1ContactMessageById: build.query({
        query: ({ id }) => ({ url: `/api/v1/Contact/GetContactById?id=${id}` }),
        transformResponse: (response: { data: unknown }) => response.data,
      }),
      getApiV1ContactPersonByUnionId: build.query({
        query: ({ unionId }) => ({
          url: `/api/v1/ContactPerson/GetContactPersonsByUnionId?unionId=${unionId}`,
        }),
      }),
      getApiV2Titles: build.query<ContactTitle[], undefined>({
        query: () => ({ url: `/api/v2/titles` }),
        transformResponse: (response: { data: unknown }) =>
          response.data as ContactTitle[],
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ['ContactPerson'],
    endpoints: {
      getApiV1ContactPersonByUnionId: {
        providesTags: ['ContactPerson'],
      },
      postApiV1CreateContact: {
        invalidatesTags: (result) =>
          result !== undefined ? ['ContactPerson'] : [],
      },
    },
  });

export const {
  useGetApiV1UnionByIdQuery,
  usePostApiV1CreateContactMutation,
  useGetApiV1ContactMessagesByUnionIdQuery,
  useGetApiV1ContactMessageByIdQuery,
  useGetApiV1ContactPersonByUnionIdQuery,
  useGetApiV2TitlesQuery,
} = apiContacts;

type ContactTitle = { id: number; name: string };
