import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { orUndefined } from 'helpers/orUndefined';
import { usePermissions } from 'hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { User } from 'types/user';
import { CollapsibleListItem } from './CollapsibleListItem';
import { list } from './sidebarListItems';

export function SidebarList({ user }: { user: User }) {
  const { t } = useTranslation(undefined, { keyPrefix: 'sidebar' });
  const isAllowed = usePermissions();
  const location = useLocation();

  return (
    <List>
      {list.filter(isAllowed).map((props) => {
        if ('to' in props) {
          const { name, to, Icon } = props;
          const isActive = location.pathname === to;
          return (
            <ListItemButton
              {...{
                key: name,
                to,
                component: Link,
                sx: (theme) => ({
                  color: orUndefined(isActive, theme.palette.primary.main),
                }),
              }}
            >
              <ListItemIcon
                style={{ minWidth: 32 }}
                children={<Icon color={orUndefined(isActive, 'primary')} />}
              />

              <ListItemText primary={t(name)} />
            </ListItemButton>
          );
        }
        return <CollapsibleListItem key={props.name} {...props} />;
      })}
    </List>
  );
}
