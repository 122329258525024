import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';
import { isAuth } from 'helpers/isAuth';

const routes = { HOME: '/' };

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: process.env.REACT_APP_API_URL }): BaseQueryFn =>
  async ({ url, method, data: _data, params }) => {
    const auth = isAuth();
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data: _data,
        params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return {
        data: result.data,
      };
    } catch (err) {
      if (err instanceof AxiosError)
        return {
          error: {
            status: err.response?.status,
            data: {
              ...err.response?.data,
              succeded: false,
              status: err.response?.status,
              statusText: err.response?.statusText,
            },
          },
        };
      return {
        error: {
          status: 'NON_AXIOS_ERROR',
          data: {
            data: null,
            succeded: false,
          },
        },
      };
    } finally {
      if (auth && !isAuth()) {
        window.location.replace(routes.HOME);
        window.location.reload();
      }
    }
  };
