import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import styled from '@mui/material/styles/styled';
import { ReactComponent as AvatarSvg } from './avatar.svg';

const SIZE = 200;

const getAvatarStyles = (width: number | string) => ({
  borderRadius: '50%',
  width: width,
  height: width,
  minWidth: width,
  minHeight: width,
});

const AvatarImg = styled('img')(({ theme, width = SIZE }) => ({
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  left: 0,
  ...getAvatarStyles(width),
}));

const AvatarPlaceholder = styled(AvatarSvg)(({ theme, width = SIZE }) => ({
  fill: theme.palette.grey[400],
  ...getAvatarStyles(width),
}));

export const Avatar = ({
  photo,
  onDelete,
}: {
  photo?: string;
  onDelete: () => void;
}) => (
  <div style={{ position: 'relative', width: SIZE, height: SIZE }}>
    {!!(photo && onDelete) && (
      <IconButton
        color="error"
        style={{ position: 'absolute', right: 0 }}
        size="small"
        onClick={onDelete}
      >
        <CloseIcon />
      </IconButton>
    )}
    {photo && <AvatarImg alt="portrait" src={photo} />}
    <AvatarPlaceholder aria-label="portrait" />
  </div>
);
