import { connect } from 'react-redux';
import { checkPermissions } from './checkPermissions';

const AccessControl = ({
  unionsWithPermissions,
  permissionsName,
  currentUser,
  allowedPermissions,
  selectedUnionId,
  children,
  renderNoAccess,
}) => {
  const permitted = checkPermissions(
    unionsWithPermissions,
    allowedPermissions,
    currentUser,
    selectedUnionId,
    permissionsName
  );
  if (unionsWithPermissions && permitted) return children;

  return renderNoAccess();
};

AccessControl.defaultProps = {
  unionsWithPermissions: [],
  allowedPermissions: [],
  currentUser: {},
  permissions: [],
  permissionsName: '',
  selectedUnionId: null,
  renderNoAccess: () => null,
};

const mapStateToProps = (state) => ({
  selectedUnionId: state.unions.selectedUnion,
  unionsWithPermissions: state.unions.dropdownUnions,
  currentUser: state.api?.queries?.['getApiV1Auth(undefined)']?.data?.data,
});

export default connect(mapStateToProps)(AccessControl);
