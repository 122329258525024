export const createImageBitmap =
  window.createImageBitmap ||
  ((blob: Blob) =>
    new Promise((resolve) => {
      let img = document.createElement('img');
      img.addEventListener('load', () =>
        resolve(this as unknown as ImageBitmap)
      );
      img.src = URL.createObjectURL(blob);
    }));
