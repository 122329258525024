import { getImageData } from './getImageData';
import { useImageUploadBase64Props } from './useImageUploadBase64';

const isSmallFile = (file: File, maxFileSize?: number) =>
  maxFileSize && file.size < maxFileSize;

export const isSmallSvg = (file: File, maxFileSize?: number) =>
  file.type.includes('svg') && isSmallFile(file, maxFileSize);

export const isSmallTargetType = async (
  file: File,
  {
    type,
    maxFileSize,
    maxWidth,
    maxHeight,
    aspectRatio,
  }: Pick<
    useImageUploadBase64Props,
    'maxWidth' | 'maxHeight' | 'maxFileSize' | 'aspectRatio' | 'type'
  >
) => {
  const { width, height } = await getImageData(file);
  return (
    (!type || file.type === type) &&
    (!maxFileSize || isSmallFile(file, maxFileSize)) &&
    (!maxWidth || maxWidth >= width) &&
    (!maxHeight || maxHeight >= height) &&
    (!aspectRatio || (maxWidth === width && maxHeight === height))
  );
};
