import { isSuperAdmin } from 'helpers/isSuperAdmin';

// FIXME: This is legacy code, needs major refactor!

export const checkPermissions = (
  userPermissions = [],
  allowedPermissions = [],
  currentUser = {},
  selectedUnionId = null,
  permissionsName = [] // this should be an Array
) => {
  if (
    !allowedPermissions.length ||
    !permissionsName.length ||
    isSuperAdmin(currentUser)
  )
    return true;

  const selectedUnion = userPermissions?.find(
    (permis) => permis.id === selectedUnionId
  );

  if (selectedUnion === undefined) return false;

  const seletedUnionPermissions = selectedUnion?.rolePermissions.filter(
    (permissionsRow) =>
      permissionsName.some(
        (permName) => permName === permissionsRow.systemFunctionName
      )
  );

  if (seletedUnionPermissions === undefined) return false;

  var cond = seletedUnionPermissions.some(
    (perm) =>
      !allowedPermissions.some(
        (permission) =>
          perm[permission] !== true &&
          permissionsName.some((s) => s === perm.systemFunctionName)
      )
  );

  return cond;
};
