import * as actionTypes from '../actions/actionTypes';

const initialState = {
  snackbarOpen: false,
  snackbarType: 'warning',
  snackbarMessage: '',

  email: '',
};

const setSnackbar = (state, action) => {
  return {
    ...state,
    snackbarOpen: action.snackbar.open,
    snackbarType: action.snackbar.type,
    snackbarMessage: action.snackbar.message,
  };
};

const setCheckEmail = (state, action) => {
  return {
    ...state,
    email: action.email,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SNACKBAR:
      return setSnackbar(state, action);
    case actionTypes.SET_CHECK_EMAIL:
      return setCheckEmail(state, action);
    default:
      return state;
  }
};

export default reducer;
