import { encode, resize } from '@radulle/squoosh';
import { arrayBufferToBase64 } from 'helpers/arrayBufferToBase64';
import { ChangeEventHandler, useRef, useState } from 'react';
import { getImageData } from './getImageData';
import { isSmallSvg, isSmallTargetType } from './helpers';

export interface useImageUploadBase64Props {
  onImageUpload: (base64: string, originalFile: File) => void;
  maxWidth?: number;
  maxHeight?: number;
  maxFileSize?: number;
  aspectRatio?: number;
  type?: keyof typeof encode;
  onValidate?: (file: File) => Promise<boolean>;
  onError?: (error: any) => void;
}

export function useImageUploadBase64({
  onImageUpload,
  maxWidth,
  maxHeight,
  maxFileSize,
  aspectRatio,
  type,
  onValidate = () => Promise.resolve(true),
  onError,
}: useImageUploadBase64Props) {
  const ref = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
    ref.current?.click();
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
    setIsLoading(true);
    try {
      const file = event.target.files?.[0];
      if (!file) return setIsLoading(false);
      if (!(await onValidate(file))) return setIsLoading(false);

      // skip optimization
      if (
        !type ||
        isSmallSvg(file, maxFileSize) ||
        (await isSmallTargetType(file, {
          maxFileSize,
          type,
          maxHeight,
          maxWidth,
          aspectRatio,
        }))
      ) {
        const base64 = await arrayBufferToBase64(
          await file.arrayBuffer(),
          file.type
        );
        onImageUpload(base64, file);
        setIsLoading(false);
        return;
      }

      let { width, height, data } = await getImageData(file, aspectRatio);

      // resize
      const ar = width / height;
      if (maxWidth && width > maxWidth) {
        const mHeight = Math.round(maxWidth / ar);
        data = await resize(data, {
          width: maxWidth,
          height: mHeight,
        });
      }
      if (maxHeight && height > maxHeight) {
        const mWidth = Math.round(maxHeight * ar);
        data = await resize(data, {
          width: mWidth,
          height: maxHeight,
        });
      }

      // compress
      const base64 = await arrayBufferToBase64(await encode[type](data), type);

      onImageUpload(base64, file);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      onError?.(e);
    }
  };

  return {
    ref,
    onClick,
    onChange,
    isLoading,
  };
}
