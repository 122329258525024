import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
  Updater,
} from '@tanstack/table-core';
import { useAppDispatch } from 'hooks/useRedux';
import { useMemo } from 'react';

interface TableState {
  columnFilters: ColumnFiltersState;
  globalFilter: string;
  sorting: SortingState;
  pagination: PaginationState;
  enableRowOrdering: boolean;
  isFullScreen: boolean;
  showGlobalFilter: boolean;
}

export function tableSliceFactory(
  name: string,
  initial: Partial<TableState> = {}
) {
  const initialState: TableState = {
    columnFilters: [],
    globalFilter: '',
    sorting: [],
    pagination: { pageIndex: 0, pageSize: 10 },
    enableRowOrdering: false,
    isFullScreen: false,
    showGlobalFilter: false,
    ...initial,
  };

  const slice = createSlice({
    name,
    initialState,
    reducers: {
      handleTableColumnFilters(
        state,
        action: PayloadAction<ColumnFiltersState>
      ) {
        state.columnFilters = action.payload;
        state.pagination.pageIndex = initialState.pagination.pageIndex;
      },
      handleTableGlobalFilter(state, action: PayloadAction<string>) {
        state.globalFilter = action.payload;
        state.pagination.pageIndex = initialState.pagination.pageIndex;
      },
      handleTableSorting(state, action: PayloadAction<SortingState>) {
        state.sorting = action.payload;
      },
      handleTablePagination(state, action: PayloadAction<PaginationState>) {
        const isPageSizeUpdated =
          state.pagination.pageSize !== action.payload.pageSize;

        state.pagination = action.payload;

        if (isPageSizeUpdated)
          state.pagination.pageIndex = initialState.pagination.pageIndex;
      },
      handleTableIsFullScreen(state, action: PayloadAction<boolean>) {
        state.isFullScreen = action.payload;
      },
      handleTableShowGlobalFilter(state, action: PayloadAction<boolean>) {
        state.showGlobalFilter = action.payload;
      },
      toggleTableEnableRowOrdering(state) {
        state.enableRowOrdering = !state.enableRowOrdering;
      },
    },
  });

  const actions = slice.actions;

  function useTableHandlers() {
    const dispatch = useAppDispatch();

    return useMemo(
      () => ({
        onColumnFiltersChange: getHandler<ColumnFiltersState>(
          dispatch,
          actions.handleTableColumnFilters,
          'columnFilters'
        ),
        onGlobalFilterChange: getHandler<string>(
          dispatch,
          actions.handleTableGlobalFilter,
          'globalFilter'
        ),
        onPaginationChange: getHandler<PaginationState>(
          dispatch,
          actions.handleTablePagination,
          'pagination'
        ),
        onSortingChange: getHandler<SortingState>(
          dispatch,
          actions.handleTableSorting,
          'sorting'
        ),
        onIsFullScreenChange: getHandler<boolean>(
          dispatch,
          actions.handleTableIsFullScreen,
          'isFullScreen'
        ),
        onShowGlobalFilterChange: getHandler<boolean>(
          dispatch,
          actions.handleTableShowGlobalFilter,
          'showGlobalFilter'
        ),
        onToggleEnableRowOrdering: () =>
          dispatch(actions.toggleTableEnableRowOrdering()),
      }),
      [dispatch]
    );
  }

  function getHandler<T>(
    dispatch: ReturnType<typeof useAppDispatch>,
    action: typeof actions[keyof typeof actions],
    field: keyof TableState
  ) {
    return (v: Updater<T>) =>
      dispatch((dispatch, getState) => {
        const globalState = getState();
        const state = globalState[
          name as keyof typeof globalState
        ] as TableState;
        if (typeof v === 'function')
          return void dispatch((action as any)((v as any)(state[field])));
        return void dispatch((action as any)(v));
      });
  }

  return { slice, useTableHandlers };
}
