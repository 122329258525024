import { Avatar, Typography } from '@mui/material';
import { User } from 'types/user';

export function Profile({ user }: { user: User }) {
  return (
    <>
      <Avatar
        sx={(theme) => ({
          width: '4rem',
          height: '4rem',
          borderWidth: '6px',
          borderStyle: 'double',
          borderColor: theme.palette.background.default,
          background: theme.palette.grey[300],
        })}
      />
      <Typography variant="subtitle1" fontWeight="bold">
        {user.firstName} {user.lastName}
      </Typography>
      <Typography variant="subtitle2">{user.userType}</Typography>
    </>
  );
}
