import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  masterUnionsLoading: false,
  federalLocationsLoading: false,
  crossOrganizationsLoading: false,
  union: null,
  unions: [],
  unionList: [],
  masterUnions: [],
  federalLocations: [],
  crossOrganizations: [],
  unionsByMasterUnion: [],
  selectedUnion: null,
  allUnionsGroupedByMasterUnions: [],
  dropdownUnions: [],
  stateUnionsLoading: false,
  stateUnions: [],
};

// Select union
const selectUnion = (state, action) => {
  return {
    ...state,
    selectedUnion: action.selectedUnion,
  };
};

// Select all unions grouped by master unions
const selectUnionsGroupedByMasterUnions = (state, action) => {
  return {
    ...state,
    allUnionsGroupedByMasterUnions: action.allUnionsGroupedByMasterUnions,
  };
};

// Create Union
const createUnionStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const createUnionSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const createUnionFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const fetchUnionListStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
  };
};

const fetchUnionListSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
    unionList: action.unionList.data,
  };
};

const fetchUnionListFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const fetchMasterUnionsStart = (state, action) => {
  return {
    ...state,
    masterUnionsLoading: true,
    masterUnionsError: false,
  };
};

const fetchMasterUnionsSuccess = (state, action) => {
  return {
    ...state,
    masterUnionsLoading: false,
    masterUnionsError: false,
    masterUnions: action.unionList.data,
  };
};

const fetchMasterUnionsFail = (state, action) => {
  return {
    ...state,
    masterUnionsLoading: false,
    masterUnionsError: action.error,
  };
};

const fetchFederalLocationsStart = (state, action) => {
  return {
    ...state,
    federalLocationsLoading: true,
    federalLocationsError: false,
  };
};

const fetchFederalLocationsSuccess = (state, action) => {
  return {
    ...state,
    federalLocationsLoading: false,
    federalLocationsError: false,
    federalLocations: action.locations.data,
  };
};

const fetchFederalLocationsFail = (state, action) => {
  return {
    ...state,
    federalLocationsLoading: false,
    federalLocationsError: action.error,
  };
};

const fetchCrossOrganizationsStart = (state, action) => {
  return {
    ...state,
    crossOrganizationsLoading: true,
    crossOrganizationsError: false,
  };
};

const fetchCrossOrganizationsSuccess = (state, action) => {
  return {
    ...state,
    crossOrganizationsLoading: false,
    crossOrganizationsError: false,
    crossOrganizations: action.organizations.data,
  };
};

const fetchCrossOrganizationsFail = (state, action) => {
  return {
    ...state,
    crossOrganizationsLoading: false,
    crossOrganizationsError: action.error,
  };
};

// Edit Union
const editUnionStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const editUnionSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const editUnionFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Get Union By Id
const getUnionStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
  };
};

const getUnionSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
    union: action.union.data,
  };
};

const getUnionFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const getUnionRssFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

// Get Union By Id
const getUnionRssStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
  };
};

const getUnionRssSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
    unionRssLink: action.unionRssLink.data.url,
  };
};

const fetchUnionsByMasterUnionIdStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
  };
};

const fetchUnionsByMasterUnionIdSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
    unionsByMasterUnion: action.unionsByMasterUnion.data,
  };
};

const fetchUnionsByMasterUnionIdFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

// Fetch dropdown unions for sidebar
const fetchDropdownUnionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false,
  };
};

const fetchDropdownUnionsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
    dropdownUnions: action.dropdownUnions,
  };
};

const fetchDropdownUnionsFail = (state, action) => {
  return {
    ...state,
    loading: false,
    error: action.error,
  };
};

const fetchStateUnionsStart = (state, action) => {
  return {
    ...state,
    stateUnionsLoading: true,
    stateUnionsError: false,
  };
};

const fetchStateUnionsSuccess = (state, action) => {
  return {
    ...state,
    stateUnionsLoading: false,
    stateUnionsError: false,
    stateUnions: action.stateUnions.data,
  };
};

const fetchStateUnionsFail = (state, action) => {
  return {
    ...state,
    stateUnionsLoading: false,
    stateUnionsError: action.error,
  };
};

// Fetch District Unions
const fetchDistrictUnionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchDistrictUnionsSuccess = (state, action) => {
  return {
    ...state,
    districtUnions: action.districtUnions,
    loading: false,
    error: false,
  };
};

const fetchDistrictUnionsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_UNION_START:
      return createUnionStart(state, action);
    case actionTypes.CREATE_UNION_SUCCESS:
      return createUnionSuccess(state, action);
    case actionTypes.CREATE_UNION_FAIL:
      return createUnionFail(state, action);
    case actionTypes.FETCH_ALL_UNIONS_START:
      return fetchUnionListStart(state, action);
    case actionTypes.FETCH_ALL_UNIONS_SUCCESS:
      return fetchUnionListSuccess(state, action);
    case actionTypes.FETCH_ALL_UNIONS_FAIL:
      return fetchUnionListFail(state, action);
    case actionTypes.FETCH_MASTER_UNIONS_START:
      return fetchMasterUnionsStart(state, action);
    case actionTypes.FETCH_MASTER_UNIONS_SUCCESS:
      return fetchMasterUnionsSuccess(state, action);
    case actionTypes.FETCH_MASTER_UNIONS_FAIL:
      return fetchMasterUnionsFail(state, action);
    case actionTypes.FETCH_FEDERAL_LOCATIONS_START:
      return fetchFederalLocationsStart(state, action);
    case actionTypes.FETCH_FEDERAL_LOCATIONS_SUCCESS:
      return fetchFederalLocationsSuccess(state, action);
    case actionTypes.FETCH_FEDERAL_LOCATIONS_FAIL:
      return fetchFederalLocationsFail(state, action);
    case actionTypes.FETCH_CROSS_ORGANIZATIONS_START:
      return fetchCrossOrganizationsStart(state, action);
    case actionTypes.FETCH_CROSS_ORGANIZATIONS_SUCCESS:
      return fetchCrossOrganizationsSuccess(state, action);
    case actionTypes.FETCH_CROSS_ORGANIZATIONS_FAIL:
      return fetchCrossOrganizationsFail(state, action);
    case actionTypes.EDIT_UNION_START:
      return editUnionStart(state, action);
    case actionTypes.EDIT_UNION_SUCCESS:
      return editUnionSuccess(state, action);
    case actionTypes.EDIT_UNION_FAIL:
      return editUnionFail(state, action);
    case actionTypes.GET_UNION_START:
      return getUnionStart(state, action);
    case actionTypes.GET_UNION_SUCCESS:
      return getUnionSuccess(state, action);
    case actionTypes.GET_UNION_FAIL:
      return getUnionFail(state, action);
    case actionTypes.GET_UNION_RSS_START:
      return getUnionRssStart(state, action);
    case actionTypes.GET_UNION_RSS_SUCCESS:
      return getUnionRssSuccess(state, action);
    case actionTypes.GET_UNION_RSS_FAIL:
      return getUnionRssFail(state, action);
    case actionTypes.FETCH_UNIONS_BY_MASTER_UNION_ID_START:
      return fetchUnionsByMasterUnionIdStart(state, action);
    case actionTypes.FETCH_UNIONS_BY_MASTER_UNION_ID_SUCCESS:
      return fetchUnionsByMasterUnionIdSuccess(state, action);
    case actionTypes.FETCH_UNIONS_BY_MASTER_UNION_ID_FAIL:
      return fetchUnionsByMasterUnionIdFail(state, action);
    case actionTypes.SELECT_UNION_SUCCESS:
      return selectUnion(state, action);
    case actionTypes.SELECT_UNIONS_GROUPED_BY_MASTER_SUCCESS:
      return selectUnionsGroupedByMasterUnions(state, action);
    case actionTypes.FETCH_DROPDOWN_UNIONS_START:
      return fetchDropdownUnionsStart(state, action);
    case actionTypes.FETCH_DROPDOWN_UNIONS_SUCCESS:
      return fetchDropdownUnionsSuccess(state, action);
    case actionTypes.FETCH_DROPDOWN_UNIONS_FAIL:
      return fetchDropdownUnionsFail(state, action);
    case actionTypes.FETCH_STATE_UNIONS_START:
      return fetchStateUnionsStart(state, action);
    case actionTypes.FETCH_STATE_UNIONS_SUCCESS:
      return fetchStateUnionsSuccess(state, action);
    case actionTypes.FETCH_STATE_UNIONS_FAIL:
      return fetchStateUnionsFail(state, action);
    case actionTypes.FETCH_DISTRICT_UNIONS_START:
      return fetchDistrictUnionsStart(state, action);
    case actionTypes.FETCH_DISTRICT_UNIONS_SUCCESS:
      return fetchDistrictUnionsSuccess(state, action);
    case actionTypes.FETCH_DISTRICT_UNIONS_FAIL:
      return fetchDistrictUnionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
