import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { orUndefined } from 'helpers/orUndefined';
import { usePermissions } from 'hooks/usePermissions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CollapsibleListItemProps } from './sidebarListItems';

export function CollapsibleListItem({
  name,
  Icon,
  items,
}: CollapsibleListItemProps) {
  const { t } = useTranslation(undefined, { keyPrefix: 'sidebar' });

  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);
  const isAllowed = usePermissions();

  const location = useLocation();

  const hasActive = items.some((e) => e.to === location.pathname);

  return (
    <>
      <ListItemButton
        {...{
          onClick,
          component: Link,
          sx: (theme) => ({
            color: orUndefined(hasActive, theme.palette.primary.main),
          }),
        }}
      >
        <ListItemIcon
          style={{ minWidth: 32 }}
          children={<Icon color={orUndefined(hasActive, 'primary')} />}
        />
        <ListItemText primary={t(name)} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List style={{ marginLeft: 16 }}>
          {items.filter(isAllowed).map(({ name, to, Icon }) => {
            const isActive = location.pathname === to;

            return (
              <ListItemButton
                {...{
                  key: name,
                  to,
                  component: Link,
                  sx: (theme) => ({
                    color: orUndefined(isActive, theme.palette.primary.main),
                  }),
                }}
              >
                <ListItemIcon
                  style={{ minWidth: 32 }}
                  children={<Icon color={orUndefined(isActive, 'primary')} />}
                />
                <ListItemText primary={t(name)} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
