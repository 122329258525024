import * as actionTypes from '../actions/actionTypes';

const initialState = {
  error: null,
  loading: false,
  crossSectionalOrganizations: [],
  federalAssociations: [],
  federalStates: [],
  unions: [],
  permissions: [],
  newUserId: null,
  systemFunctions: [],
  permissionsByUser: [],
};

// Add User
const addUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const addUserSuccess = (state, action) => {
  return {
    ...state,
    newUserId: action.userId,
    loading: false,
    error: false,
  };
};

const addUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Add Role
const addRoleStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const addRoleSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const addRoleFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Organizations
const fetchCrossSectionalOrganizationsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchCrossSectionalOrganizationsSuccess = (state, action) => {
  return {
    ...state,
    crossSectionalOrganizations: action.crossSectionalOrganizations,
    loading: false,
    error: false,
  };
};

const fetchCrossSectionalOrganizationsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Federal States
const fetchFederalStatesStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchFederalStatesSuccess = (state, action) => {
  return {
    ...state,
    federalStates: action.federalStates,
    loading: false,
    error: false,
  };
};

const fetchFederalStatesFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch District Unions
const fetchDistrictUnionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchDistrictUnionsSuccess = (state, action) => {
  return {
    ...state,
    districtUnions: action.districtUnions,
    loading: false,
    error: false,
  };
};

const fetchDistrictUnionsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Permissions
const fetchPermissionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchPermissionsSuccess = (state, action) => {
  return {
    ...state,
    permissions: action.permissions,
    loading: false,
    error: false,
  };
};

const fetchPermissionsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch Permissions By User
const fetchPermissionsByUserStart = (state, action) => {
  return {
    ...state,
    loading: true,
    permissionsByUser: [],
  };
};

const fetchPermissionsByUserSuccess = (state, action) => {
  return {
    ...state,
    permissionsByUser: action.permissions,
    loading: false,
    error: false,
  };
};

const fetchPermissionsByUserFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
    permissionsByUser: [],
  };
};

// Fetch Unions
const fetchUnionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchUnionsSuccess = (state, action) => {
  return {
    ...state,
    unions: action.unions,
    loading: false,
    error: false,
  };
};

const fetchUnionsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Fetch System Functions
const fetchSystemFunctionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const fetchSystemFunctionsSuccess = (state, action) => {
  return {
    ...state,
    systemFunctions: action.systemFunctions,
    loading: false,
    error: false,
  };
};

const fetchSystemFunctionsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

// Update
const updateRolePermissionsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
};

const updateRolePermissionsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    error: false,
  };
};

const updateRolePermissionsFail = (state, action) => {
  return {
    ...state,
    error: action.error,
    loading: false,
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_USER_START:
      return addUserStart(state, action);
    case actionTypes.ADD_USER_SUCCESS:
      return addUserSuccess(state, action);
    case actionTypes.ADD_USER_FAIL:
      return addUserFail(state, action);
    case actionTypes.ADD_ROLE_START:
      return addRoleStart(state, action);
    case actionTypes.ADD_ROLE_SUCCESS:
      return addRoleSuccess(state, action);
    case actionTypes.ADD_ROLE_FAIL:
      return addRoleFail(state, action);
    case actionTypes.FETCH_CROSS_SECTIONAL_ORGANIZATIONS_START:
      return fetchCrossSectionalOrganizationsStart(state, action);
    case actionTypes.FETCH_CROSS_SECTIONAL_ORGANIZATIONS_SUCCESS:
      return fetchCrossSectionalOrganizationsSuccess(state, action);
    case actionTypes.FETCH_CROSS_SECTIONAL_ORGANIZATIONS_FAIL:
      return fetchCrossSectionalOrganizationsFail(state, action);
    case actionTypes.FETCH_FEDERAL_STATES_START:
      return fetchFederalStatesStart(state, action);
    case actionTypes.FETCH_FEDERAL_STATES_SUCCESS:
      return fetchFederalStatesSuccess(state, action);
    case actionTypes.FETCH_FEDERAL_STATES_FAIL:
      return fetchFederalStatesFail(state, action);
    case actionTypes.FETCH_DISTRICT_UNIONS_START:
      return fetchDistrictUnionsStart(state, action);
    case actionTypes.FETCH_DISTRICT_UNIONS_SUCCESS:
      return fetchDistrictUnionsSuccess(state, action);
    case actionTypes.FETCH_DISTRICT_UNIONS_FAIL:
      return fetchDistrictUnionsFail(state, action);
    case actionTypes.FETCH_PERMISSIONS_START:
      return fetchPermissionsStart(state, action);
    case actionTypes.FETCH_PERMISSIONS_SUCCESS:
      return fetchPermissionsSuccess(state, action);
    case actionTypes.FETCH_PERMISSIONS_FAIL:
      return fetchPermissionsFail(state, action);
    case actionTypes.FETCH_PERMISSIONS_BY_USER_START:
      return fetchPermissionsByUserStart(state, action);
    case actionTypes.FETCH_PERMISSIONS_BY_USER_SUCCESS:
      return fetchPermissionsByUserSuccess(state, action);
    case actionTypes.FETCH_PERMISSIONS_BY_USER_FAIL:
      return fetchPermissionsByUserFail(state, action);
    case actionTypes.FETCH_UNIONS_START:
      return fetchUnionsStart(state, action);
    case actionTypes.FETCH_UNIONS_SUCCESS:
      return fetchUnionsSuccess(state, action);
    case actionTypes.FETCH_UNIONS_FAIL:
      return fetchUnionsFail(state, action);
    case actionTypes.FETCH_SYSTEM_FUNCTIONS_START:
      return fetchSystemFunctionsStart(state, action);
    case actionTypes.FETCH_SYSTEM_FUNCTIONS_SUCCESS:
      return fetchSystemFunctionsSuccess(state, action);
    case actionTypes.FETCH_SYSTEM_FUNCTIONS_FAIL:
      return fetchSystemFunctionsFail(state, action);
    case actionTypes.UPDATE_ROLE_PERMISSIONS_START:
      return updateRolePermissionsStart(state, action);
    case actionTypes.UPDATE_ROLE_PERMISSIONS_SUCCESS:
      return updateRolePermissionsSuccess(state, action);
    case actionTypes.UPDATE_ROLE_PERMISSIONS_FAIL:
      return updateRolePermissionsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
