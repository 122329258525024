import { Card, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { usePostApiV1AuthLoginMutation } from 'store/api';

import Logo from '../../assets/images/Cockpit_logo.png';

const StyledCont = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  gap: theme.spacing(6),
  padding: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingInline: theme.spacing(10),
  paddingBlock: theme.spacing(5),
  textAlign: 'center',
  maxWidth: theme.breakpoints.values.md,
}));

const StyledLink = styled(Link)(() => ({
  display: 'block',
  cursor: 'pointer',
}));

const hideEmail = function (email) {
  return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
    for (let i = 0; i < gp3.length; i++) {
      gp2 += '*';
    }
    return gp2;
  });
};

const CheckEmail = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [submitLogin, { isLoading }] = usePostApiV1AuthLoginMutation();
  const { enqueueSnackbar } = useSnackbar();

  if (!searchParams.has('u') || !state?.password)
    return <Navigate to="/" replace />;
  const email = searchParams.get('u');

  const handleResendClick = async function (event) {
    event.preventDefault();
    try {
      const result = await submitLogin({ email, password: state.password });
      if (result.data?.succeeded === true)
        return enqueueSnackbar(
          result.data.messages?.[0] || i18next.t('Login.ResendLinkSuccess'),
          { variant: 'success' }
        );
      return enqueueSnackbar(
        result.data?.messages?.[0] ||
          result.data?.message ||
          i18next.t('Login.ResendLinkError'),
        { variant: 'error' }
      );
    } catch (e) {
      return enqueueSnackbar(
        e?.data?.messages?.[0] ||
          e?.data?.message ||
          i18next.t('Login.ResendLinkError'),
        {
          variant: 'error',
        }
      );
    }
  };

  return (
    <StyledCont>
      <img src={Logo} alt="Logo" height={81} width={195} />
      <StyledCard>
        <Typography color="primary" fontWeight="bold" variant="caption">
          {t('CheckEmail.Title')}
        </Typography>
        <Typography variant="subtitle1">
          {t('CheckEmail.Content1')}
          <Typography color="primary">{hideEmail(email)}</Typography>
        </Typography>

        <Typography variant="subtitle1">{t('CheckEmail.Content2')}</Typography>
        <Typography variant="subtitle1">
          {t('CheckEmail.Content3')}
          <StyledLink
            color="primary"
            underline="hover"
            disabled={isLoading}
            onClick={handleResendClick}
          >
            {t('CheckEmail.Content4')}
          </StyledLink>
        </Typography>
      </StyledCard>
    </StyledCont>
  );
};

export default CheckEmail;
