import { useGetApiV1AuthQueryState } from 'store/api';
import { User } from 'types/user';

export function useUser(): [
  null | User,
  ReturnType<typeof useGetApiV1AuthQueryState>
] {
  const result = useGetApiV1AuthQueryState(undefined);
  if (result.isSuccess) return [result?.data?.data, result];
  return [null, result];
}
