import { api } from './api';
import { Social } from './types';

export const apiSocials = api
  .injectEndpoints({
    endpoints: (build) => ({
      getApiV2SocialMediaTypes: build.query<Omit<Social, 'url'>[], undefined>({
        query: () => ({ url: `/api/v2/socialMediaTypes` }),
        transformResponse: (response: { data: Omit<Social, 'url'>[] }) =>
          response.data,
      }),
      getApiV2SocialsByUnionId: build.query<
        Record<string, string>,
        { unionId: number }
      >({
        query: ({ unionId }) => ({
          url: `/api/v2/unions/${unionId}/socials`,
        }),
        transformResponse: (responseData: { data: Social[] }) => {
          return Object.fromEntries(
            responseData.data.map((e) => [e.name, e.url])
          );
        },
      }),
      putApiV2SocialsByUnionId: build.mutation({
        query: ({ unionId, ...data }) => ({
          url: `/api/v2/unions/${unionId}/socials`,
          method: 'PUT',
          data,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ['Socials'],
    endpoints: {
      getApiV2SocialsByUnionId: {
        providesTags: ['Socials'],
      },
      putApiV2SocialsByUnionId: {
        invalidatesTags: ['Socials'],
      },
    },
  });

export const {
  useGetApiV2SocialMediaTypesQuery,
  useGetApiV2SocialsByUnionIdQuery,
  usePutApiV2SocialsByUnionIdMutation,
} = apiSocials;
