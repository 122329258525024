export const routes = {
  HOME: '/',
  ADVERTISMENTS: '/advertisments',
  ADVERTISMENTS_CREATE: '/advertisments/create',
  ADVERTISMENTS_EDIT: '/advertisments/:id',
  NOTIFICATIONS: '/notifications',
  NOTIFICATIONS_DETAILS: '/notifications/:id/details',
  NOTIFICATIONS_APROVE_LIST: '/notifications/approve',
  NOTIFICATIONS_APROVE: '/notifications/:id/approve',
  NOTIFICATIONS_CREATE: '/notifications/create',
  NOTIFICATIONS_EDIT: '/notifications/:id',
  COMMITTEES: '/committees',
  DOWNLOADS: '/downloads',
  DOWNLOADS_CREATE: '/downloads/create',

  DISRUPTORS: '/disruptors',
  RSS: '/rss-feeds',

  NEWS: '/news',
  NEWS_CREATE: '/news/create',
  NEWS_EDIT: '/news/:id',
  NEWS_PREVIEW: '/news/:id/preview',

  TOPICS: '/topics',
  TOPICS_CREATE: '/topics/create',
  TOPICS_EDIT: '/topics/:id',
  TOPICS_PREVIEW: '/topics/:id/preview',

  CONTENT_WIZARD: '/content-wizard',
  CONTENTS_TOPICS: '/topics',
  CONTENTS_NEWS: '/news',
  CONTENTS_RSS: '/contents/rss-feed',
  CONTENTS_DISRUPTOR: '/contents/disruptor',

  UNIONS_EMAIL: '/union-settings/emails',
  UNIONS_RSS: '/union-settings/rss-feed',
  UNIONS_FOOTER: '/union-settings/footer',
  UNIONS_CONTACT_PERSON: '/union-settings/create-contact',
  CONTACT_MESSAGES: '/union-settings/contact-messages',

  ADMINS: '/cockpit-users',
  ADMINS_CREATE: '/create-new-user',
  UNIONS: '/union-list',
  UNIONS_CREATE: '/create-new-union',

  USERS: '/users',
  USERS_REQUESTS: '/users-requests',
  USERS_DETAILS: '/users/:id',
};

export type Routes = typeof routes;
