import { darken, lighten } from '@mui/material';
import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#9632FA',
    },
    secondary: {
      main: '#5ECFFF',
      contrastText: '#FFF',
    },
    inactive: {
      main: '#eaeaea',
      dark: darken('#eaeaea', 0.05),
      light: lighten('#eaeaea', 0.05),
      contrastText: '#9ca1b3',
    },
  },
  typography: {
    fontFamily: 'DMSans',
    button: {
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 700,
    },
    h1: {
      fontFamily: 'Poppins',
      fontSize: '2rem',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: '1.4rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          height: 48,
          minHeight: 48,
        },
      },
    },
  },
});

export default theme;
