import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import ArticleIcon from '@mui/icons-material/Article';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GppGoodIcon from '@mui/icons-material/GppGood';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SmsIcon from '@mui/icons-material/Sms';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ElementType } from 'react';
import { routes } from 'routes';
import { ReactComponent as AdvertismentsIcon } from './icons/advertisments.svg';
import { ReactComponent as CommitteesIcon } from './icons/committees.svg';
import { ReactComponent as ContactIcon } from './icons/contact.svg';
import { ReactComponent as ContentIcon } from './icons/content.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import { ReactComponent as FooterIcon } from './icons/footer.svg';
import { ReactComponent as NewsIcon } from './icons/news.svg';
import { ReactComponent as RssIcon } from './icons/rss.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import { ReactComponent as TopicsIcon } from './icons/topics.svg';

export type NavigableListItemProps = {
  name: string;
  to: string;
  Icon: ElementType;
  onlySuperAdmin?: boolean;
  // TODO: clean-up
  permissionsName?: string[];
  allowedPermissions?: string[];
};

export type CollapsibleListItemProps = {
  name: string;
  Icon: ElementType;
  items: NavigableListItemProps[];
  onlySuperAdmin?: boolean;
  // TODO: clean-up
  permissionsName?: string[];
  allowedPermissions?: string[];
};

export const list: (NavigableListItemProps | CollapsibleListItemProps)[] = [
  {
    name: 'contentWizard',
    to: routes.CONTENT_WIZARD,
    Icon: InsertDriveFileIcon,
    permissionsName: ['News', 'Themen'],
    allowedPermissions: ['create'],
  },
  {
    name: 'content',
    Icon: withSvgIcon(ContentIcon),
    items: [
      {
        name: 'topics',
        to: routes.TOPICS,
        Icon: withSvgIcon(TopicsIcon),
      },
      {
        name: 'news',
        to: routes.NEWS,
        Icon: withSvgIcon(NewsIcon),
      },
      {
        name: 'rssFeed',
        to: routes.RSS,
        Icon: withSvgIcon(RssIcon),
      },
      {
        name: 'disruptor',
        to: routes.DISRUPTORS,
        Icon: withSvgIcon(NewsIcon),
        onlySuperAdmin: true,
      },
      {
        name: 'advertisments',
        to: routes.ADVERTISMENTS,
        Icon: withSvgIcon(AdvertismentsIcon),
        onlySuperAdmin: true,
      },
      {
        name: 'notifications',
        to: routes.NOTIFICATIONS,
        Icon: withSvgIcon(CircleNotificationsIcon),
      },
    ],
  },
  {
    name: 'notificationsApproval',
    to: routes.NOTIFICATIONS_APROVE_LIST,
    Icon: CircleNotificationsIcon,
    onlySuperAdmin: true,
  },
  {
    name: 'unions',
    Icon: withSvgIcon(SettingsIcon),
    items: [
      {
        name: 'unionsEmail',
        to: routes.UNIONS_EMAIL,
        Icon: withSvgIcon(EmailIcon),
        permissionsName: ['Einstellungen'],
        allowedPermissions: ['view'],
      },
      {
        name: 'unionsRss',
        to: routes.UNIONS_RSS,
        Icon: withSvgIcon(RssIcon),
        permissionsName: ['Einstellungen'],
        allowedPermissions: ['view'],
      },
      {
        name: 'downloads',
        to: routes.DOWNLOADS,
        Icon: PictureAsPdfIcon,
        permissionsName: ['Einstellungen'],
        allowedPermissions: ['view'],
      },
      {
        name: 'footer',
        to: routes.UNIONS_FOOTER,
        Icon: withSvgIcon(FooterIcon),
        permissionsName: ['Einstellungen'],
        allowedPermissions: ['view'],
      },
      {
        name: 'contactPerson',
        to: routes.UNIONS_CONTACT_PERSON,
        Icon: withSvgIcon(ContactIcon),
        permissionsName: ['Einstellungen'],
        allowedPermissions: ['view'],
      },
      {
        name: 'committees',
        to: routes.COMMITTEES,
        Icon: withSvgIcon(CommitteesIcon),
        permissionsName: ['Einstellungen'],
        allowedPermissions: ['view'],
      },
    ],
  },
  {
    name: 'contactMessages',
    to: routes.CONTACT_MESSAGES,
    Icon: withSvgIcon(EmailIcon),
    permissionsName: ['Kontaktanfragen'],
    allowedPermissions: ['view'],
  },
  {
    name: 'usersManagement',
    Icon: AccountCircleIcon,
    items: [
      {
        name: 'usersRequests',
        to: routes.USERS_REQUESTS,
        Icon: SmsIcon,
      },
      {
        name: 'usersApproved',
        to: routes.USERS,
        Icon: ArticleIcon,
      },
    ],
  },
  {
    name: 'adminsManagement',
    Icon: GppGoodIcon,
    onlySuperAdmin: true,
    items: [
      {
        name: 'adminsCreate',
        to: routes.ADMINS_CREATE,
        Icon: CheckCircleIcon,
      },
      {
        name: 'adminsOverview',
        to: routes.ADMINS,
        Icon: DashboardIcon,
      },
      {
        name: 'unionsCreate',
        to: routes.UNIONS_CREATE,
        Icon: AddBoxRoundedIcon,
      },
      {
        name: 'unionsOverview',
        to: routes.UNIONS,
        Icon: DashboardIcon,
      },
    ],
  },
];

function withSvgIcon(Icon: ElementType) {
  return (props: SvgIconProps) => <SvgIcon {...props} component={Icon} />;
}
