import { PowerSettingsNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useOnBeforeUnload } from 'hooks/useOnBeforeUnload';
import { useTranslation } from 'react-i18next';
import { usePostApiV1AuthLogoutMutation } from 'store/api';

export function Logout() {
  const { t } = useTranslation();
  const [logout] = usePostApiV1AuthLogoutMutation();
  const clearUnloadCallback = useOnBeforeUnload();

  const handleLogout = () => {
    if (window.confirm(t('confirmLogout'))) {
      clearUnloadCallback();
      logout(undefined);
    }
  };

  return (
    <IconButton onClick={handleLogout}>
      <PowerSettingsNew />
    </IconButton>
  );
}
