import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CustomizedSnackbars from 'components/Shared/Snackbar';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreProvider } from 'store';
import App from './App';
import './services/i18n';
import './styles/overrides.css';
import theme from './styles/theme';

const app = (
  <SnackbarProvider maxSnack={3}>
    <StoreProvider>
      <Router basename="/admin">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CustomizedSnackbars />
          <App />
        </ThemeProvider>
      </Router>
    </StoreProvider>
  </SnackbarProvider>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(app);
