import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { selectUnion } from 'store/actions/unionActions';

export function UnionSelect() {
  const unionsList = useAppSelector((state) => state.unions.dropdownUnions);
  const union = useAppSelector((state) => state.unions.selectedUnion);

  const dispatch = useAppDispatch();
  const handleChangeSelectedUnion = (
    event: SelectChangeEvent<{ value: number }>
  ) => dispatch(selectUnion(event.target.value));

  return (
    <FormControl
      variant="outlined"
      fullWidth
      style={{ width: '100%', marginBlock: '1rem' }}
    >
      <Select value={union || ''} onChange={handleChangeSelectedUnion}>
        {unionsList.map((item: any) => (
          <MenuItem value={item.id} key={item.id} children={item.name} />
        ))}
      </Select>
    </FormControl>
  );
}
