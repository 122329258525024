import { checkPermissions } from 'components/Shared/checkPermissions';
import { isSuperAdmin } from 'helpers/isSuperAdmin';
import { useAppSelector } from 'hooks/useRedux';
import { useUser } from './useUser';

// TODO: remove checkPermissions dependency and handle permissions checking in a more meaningful way
export function usePermissions() {
  const [user] = useUser();
  const unionsWithPermissions = useAppSelector(
    (state) => state.unions.dropdownUnions
  );
  const selectedUnionId = useAppSelector((state) => state.unions.selectedUnion);

  return ({
    onlySuperAdmin,
    allowedPermissions,
    permissionsName,
  }: {
    onlySuperAdmin?: boolean;
    allowedPermissions?: string[];
    permissionsName?: string[];
  }): boolean => {
    if (!user || !unionsWithPermissions || !selectedUnionId) return false;
    if (onlySuperAdmin && !isSuperAdmin(user)) return false;
    return checkPermissions(
      unionsWithPermissions,
      allowedPermissions,
      user,
      selectedUnionId,
      permissionsName
    );
  };
}
