import { api } from './api';

export const apiAuth = api
  .injectEndpoints({
    endpoints: (build) => ({
      postApiV1AccountConfirmEmail: build.query({
        query: (data) => ({
          url: `/api/v1/account/confirmemail`,
          method: 'POST',
          data,
        }),
      }),
      postApiV1AuthLogin: build.mutation({
        query: (data) => ({
          url: `/api/v2/auth/logincockpituser`,
          method: 'POST',
          data,
        }),
      }),
      postApiV1AuthValidateCode: build.mutation({
        query: (data) => ({
          url: `/api/v2/auth/validatecode`,
          method: 'POST',
          data,
        }),
      }),
      postApiV1AuthLogout: build.mutation({
        query: () => ({
          url: `/api/v2/auth/logoutcockpit`,
          method: 'POST',
        }),
      }),
      getApiV1Auth: build.query({
        query: () => ({
          url: `/api/v1/account/getcockpituserdata`,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: ['Auth'],
    endpoints: {
      getApiV1Auth: {
        providesTags: ['Auth'],
      },
      postApiV1AuthValidateCode: {
        invalidatesTags: (result) => (result !== undefined ? ['Auth'] : []),
      },
      postApiV1AuthLogout: {
        invalidatesTags: (result) => (result !== undefined ? ['Auth'] : []),
      },
    },
  });

export const {
  usePostApiV1AccountConfirmEmailQuery,
  usePostApiV1AuthLoginMutation,
  usePostApiV1AuthValidateCodeMutation,
  useGetApiV1AuthQuery,
  usePostApiV1AuthLogoutMutation,
  endpoints: {
    getApiV1Auth: { useQueryState: useGetApiV1AuthQueryState },
  },
} = apiAuth;
