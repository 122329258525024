import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';
import { api } from './api';
import contentReducer from './reducers/contentReducer';
import notificationReducer from './reducers/notificationReducer';
import permissionsReducer from './reducers/permissionsReducer';
import unionReducer from './reducers/unionReducer';
import userReducer from './reducers/userReducer';
import advertismentsTableSlice from './slices/advertismentsTableSlice';
import articlesTableSlice from './slices/articlesTableSlice';
import committeesTableSlice from './slices/committeesTableSlice';
import disruptorsTableSlice from './slices/disruptorsTableSlice';
import downloadsTableSlice from './slices/downloadsTableSlice';
import notificationsIndependentTableSlice from './slices/notificationsIndependentTableSlice';
import notificationsTableSlice from './slices/notificationsTableSlice';
import rssFeedsTableSlice from './slices/rssFeedsTableSlice';
import usersRequestTableSlice from './slices/usersRequestTableSlice';
import usersTableSlice from './slices/usersTableSlice';
const reducer = {
  [api.reducerPath]: api.reducer,
  permissions: permissionsReducer,
  user: userReducer,
  notification: notificationReducer,
  unions: unionReducer,
  content: contentReducer,
  [committeesTableSlice.name]: committeesTableSlice.reducer,
  [advertismentsTableSlice.name]: advertismentsTableSlice.reducer,
  [downloadsTableSlice.name]: downloadsTableSlice.reducer,
  [notificationsTableSlice.name]: notificationsTableSlice.reducer,
  [notificationsIndependentTableSlice.name]:
    notificationsIndependentTableSlice.reducer,
  [articlesTableSlice.name]: articlesTableSlice.reducer,
  [usersTableSlice.name]: usersTableSlice.reducer,
  [usersRequestTableSlice.name]: usersRequestTableSlice.reducer,
  [disruptorsTableSlice.name]: disruptorsTableSlice.reducer,
  [rssFeedsTableSlice.name]: rssFeedsTableSlice.reducer,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
  devTools: {
    name: 'cockpit',
  },
});

setupListeners(store.dispatch);

export const StoreProvider: FC<PropsWithChildren<{}>> = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
