import { Box, Drawer } from '@mui/material';
import { User } from 'types/user';
import { Logo } from './Logo';
import { Logout } from './Logout';
import { Profile } from './Profile';
import { SidebarList } from './SidebarList';
import { UnionSelect } from './UnionSelect';

const width = 300;

export function Sidebar({ user }: { user: User }) {
  return (
    <Box component="nav" style={{ width, flexShrink: 0 }}>
      <Drawer
        variant="permanent"
        PaperProps={{ elevation: 4, style: { width } }}
      >
        <Logo />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          padding="1rem"
        >
          <Profile user={user} />
          <UnionSelect />
          <Logout />
        </Box>
        <SidebarList user={user} />
      </Drawer>
    </Box>
  );
}
