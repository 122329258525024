import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../../store/actions/notificationActions';

const CustomizedSnackbars = () => {
  const dispatch = useDispatch();

  const snackbarOpen = useSelector((state) => state.notification.snackbarOpen);
  const snackbarType = useSelector((state) => state.notification.snackbarType);
  const snackbarMessage = useSelector(
    (state) => state.notification.snackbarMessage
  );

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      setSnackbar({
        open: false,
        type: snackbarType,
        message: snackbarMessage,
      })
    );
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        color={snackbarType}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
