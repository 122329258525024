import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/styles';
import LoadingSpinner from 'components/Shared/LoadingSpinner';
import { Avatar } from 'elements/Avatar';
import { getImageData } from 'hooks/useImageUploadBase64/getImageData';
import { useImageUploadBase64 } from 'hooks/useImageUploadBase64/useImageUploadBase64';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

const BlurryDialog = styled(Dialog)(({ theme }) => ({
  background: '#868383 0% 0% no-repeat padding-box',
  backdropFilter: 'blur(9px)',
  boxShadow: '0px 3px 20px #0000001A',
  textAlign: 'center',
}));

const StyledTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '30px',
  fontWeight: 'bold',
  marginTop: '42px',
  marginBottom: '27px',
  color: theme.palette.primary.main,
}));

const StyledAddBtn = styled(Button)(({ theme }) => ({
  paddingInline: '20px',
  height: '60px',
  marginBlock: '60px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  width: '146px',
}));

const StyledInput = styled(TextField)(({ theme }) => ({
  color: theme.palette.primary.dark,
  height: '60px',
  borderColor: theme.palette.grey[300],
  marginTop: '14px',
}));

export const UploadImageDialog = ({
  open,
  onClose,
  onImageUpload,
  image,
  onChangeSeo,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const inputImageProps = useImageUploadBase64({
    type: 'image/jpeg',
    onImageUpload,
    maxFileSize: 5_000,
    maxWidth: 200,
    maxHeight: 200,
    aspectRatio: 1,
    onError: () =>
      enqueueSnackbar(t('Common.ImageErrror'), { variant: 'error' }),
    onValidate: async (file) => {
      if (!/jpeg|png|gif|webp|avif/.test(file.type)) {
        enqueueSnackbar(t('Common.ImageType'), { variant: 'error' });
        return false;
      }
      let { width, height } = await getImageData(file);
      if (width < 200 || height < 200) {
        enqueueSnackbar(t('Common.Image<200x200px'), { variant: 'error' });
        return false;
      }
      return true;
    },
  });

  const handleTitleLimit = (event) => {
    if (event.target.value.length <= 300) {
      return onChangeSeo(event, 'title');
    }

    enqueueSnackbar(t('Common.LimitExceeded', { count: 300 }), {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  return (
    <BlurryDialog onClose={onClose} open={open} hideBackdrop maxWidth="md">
      {inputImageProps.isLoading && <LoadingSpinner />}
      <StyledTitle variant="h6">{t('Contacts.ChangeImage')}</StyledTitle>
      <DialogActions
        sx={{
          alignSelf: 'center',
          flexDirection: 'column',
          paddingInline: '127px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            flex: 1,
            width: '100%',
          }}
        >
          <Box>
            <Box sx={{ display: 'flex' }}>
              <h3>{t('Contacts.ProfilePicture')}</h3>
              <LightTooltip title={t('Content.ImageSizeInfo')}>
                <HelpOutlineIcon
                  sx={{
                    color: theme.palette.grey[900],
                    cursor: 'pointer',
                    ml: '10px',
                  }}
                />
              </LightTooltip>
            </Box>
            <Avatar
              photo={image.fileData || image.profilePicture || image.photoMedia}
            />
          </Box>
          <Box>
            <Button
              style={{
                minHeight: '57px',
                marginLeft: '20px',
                width: '100%',
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
              size="large"
              variant="contained"
              startIcon={<PhotoCamera />}
              onClick={inputImageProps.onClick}
            >
              {t('Contacts.ChangeImage')}
              <input type="file" accept="image/*" hidden {...inputImageProps} />
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flex: 1, width: '100%' }}>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ mr: '38px', mt: '16px', border: '5px solid #EAEAEA' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              width: '100%',
            }}
          >
            <Box
              sx={{
                mt: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="labelSubtitle" sx={{ fontSize: '14px' }}>
                {t('Wizard.AlternativeText')}
              </Typography>
              <StyledInput
                type="text"
                fullWidth
                value={image?.alternateText}
                onChange={(event) => onChangeSeo(event, 'alternateText')}
              />
            </Box>
            <Box
              sx={{
                mt: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="labelSubtitle" sx={{ fontSize: 14 }}>
                {t('Wizard.Title')}
              </Typography>
              <StyledInput
                type="text"
                fullWidth
                value={image?.title}
                onChange={handleTitleLimit}
              />
            </Box>
            <Box
              sx={{
                mt: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="labelSubtitle" sx={{ fontSize: 14 }}>
                {t('Wizard.ImageSource')}
              </Typography>
              <StyledInput
                type="text"
                fullWidth
                value={image?.imageSource}
                onChange={(event) => onChangeSeo(event, 'imageSource')}
              />
            </Box>
            <Box
              sx={{
                mt: '16px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Typography variant="labelSubtitle" sx={{ fontSize: 14 }}>
                {t('Wizard.Description')}
              </Typography>
              <StyledInput
                type="text"
                fullWidth
                value={image?.description}
                onChange={(event) => onChangeSeo(event, 'description')}
              />
            </Box>
          </Box>
        </Box>

        <StyledAddBtn
          variant="contained"
          startIcon={<PhotoCamera />}
          onClick={onClose}
        >
          {t('Common.Save')}
        </StyledAddBtn>
      </DialogActions>
    </BlurryDialog>
  );
};
