import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import MessagesTable from 'components/Contacts/MessagesTable';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useGetApiV1ContactMessagesByUnionIdQuery } from 'store/api';

const StyledCont = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingInline: '50px',
  paddingBlock: '56px',
}));

const StyledTopCont = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  flexDirection: 'row',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
}));

const StyledInput = styled(TextField)(() => ({
  height: '60px',
  width: '400px',
}));

export const ContactMessages = () => {
  const unionId = useSelector((state) => state.unions.selectedUnion);
  if (!unionId) return null;
  return <ContactMessagesBase unionId={unionId} />;
};

export const ContactMessagesBase = ({ unionId }) => {
  return (
    <ContactMessagesForm
      {...{
        unionId,
      }}
    />
  );
};

const ContactMessagesForm = ({ unionId }) => {
  const [page, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [searchRTK, setSearchRTK] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchInputChange = (value) => {
    setPage(1);
    setSearch(value);
    setSearchParams(assembleParamsFromState);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchRTK(search);
    }, 1000);

    return () => clearTimeout(timer);
  }, [search, setSearchRTK]);

  const assembleParamsFromState = () => {
    let params = {};
    page && (params = { page: page });
    itemsPerPage && (params = { ...params, itemsPerPage: itemsPerPage });
    orderBy && (params = { ...params, orderBy: orderBy });
    order && (params = { ...params, order: order });
    search && (params = { ...params, search: search });

    return params;
  };

  const handleSearchParams = (params) => {
    params.page && setPage(parseInt(params.page));
    params.orderBy && setOrderBy(params.orderBy);
    params.order && setOrder(params.order);
    params.search && setSearch(params.search);
  };

  useEffect(() => {
    setSearchParams(assembleParamsFromState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, itemsPerPage, orderBy, order, search]);

  const { data: messages } = useGetApiV1ContactMessagesByUnionIdQuery(
    {
      unionId,
      page,
      itemsPerPage,
      order,
      orderBy,
      searchRTK,
    },
    { refetchOnMountOrArgChange: true }
  );

  const handleTableHeadCellClick = (cellId) => {
    if (order === 'asc') {
      setOrder('desc');
    } else {
      setOrder('asc');
    }

    setOrderBy(cellId);
  };

  useEffect(() => {
    let params = Object.fromEntries([...searchParams]);

    if (Object.keys(params).length !== 0) {
      handleSearchParams(params);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledCont>
      <StyledTopCont>
        <Box>
          <h1>{t('Menu.ContactRequests')}</h1>
          <h2>{t('Common.Administration')}</h2>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledInput
            id="search-users-input"
            placeholder={t('Common.Search')}
            type="text"
            value={search}
            onChange={(e) => handleSearchInputChange(e.target.value)}
            color="primary"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </StyledTopCont>

      <MessagesTable
        search={search}
        messages={messages || []}
        page={page}
        itemsPerPage={itemsPerPage}
        setPage={setPage}
        orderBy={orderBy}
        order={order}
        handleTableHeadCellClick={handleTableHeadCellClick}
      />
    </StyledCont>
  );
};

export default ContactMessages;
