import axios from 'axios';
import { isSuperAdmin } from 'helpers/isSuperAdmin';
import * as actionTypes from './actionTypes';
import { setSnackbar } from './notificationActions';

// Create Union
export const createUnionStart = () => {
  return {
    type: actionTypes.CREATE_UNION_START,
  };
};

export const createUnionSuccess = () => {
  return {
    type: actionTypes.CREATE_UNION_SUCCESS,
  };
};

export const createUnionFail = (error) => {
  return {
    type: actionTypes.CREATE_UNION_FAIL,
    error: error,
  };
};

export const createUnion = (data) => {
  return (dispatch) => {
    dispatch(createUnionStart());

    let url = `${process.env.REACT_APP_API_URL}/api/v1/union/create`;

    axios
      .post(url, data, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            setSnackbar({
              open: true,
              type: 'success',
              message: 'Union created!',
            })
          );
        } else {
          dispatch(
            setSnackbar({
              open: true,
              type: 'error',
              message: 'Union is not created. Something went wrong!',
            })
          );
        }

        dispatch(createUnionSuccess());
      })
      .catch((error) => {
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: 'Union is not created. Something went wrong!',
          })
        );

        dispatch(createUnionFail(error));
      });
  };
};

export const fetchUnionListStart = () => {
  return {
    type: actionTypes.FETCH_ALL_UNIONS_START,
  };
};

export const fetchUnionListSuccess = (data) => {
  return {
    type: actionTypes.FETCH_ALL_UNIONS_SUCCESS,
    unionList: data,
  };
};

export const fetchUnionListFail = (error) => {
  return {
    type: actionTypes.FETCH_ALL_UNIONS_FAIL,
    error: error,
  };
};

export const fetchUnionList = (page, itemsPerPage, orderBy, order, search) => {
  return (dispatch, getState) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v2/unions/all`;
    axios
      .get(url, {
        params: {
          PageIndex: page,
          PageSize: itemsPerPage,
          SortColumn: orderBy,
          SortOrder: order,
          FilterValue: search,
        },
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(fetchUnionListSuccess(r.data));

        // TODO: find out what this is doing and if it is needed
        const state = getState();
        const unionList = state.unions.unionList;
        const currentUser =
          state.api?.queries?.['getApiV1Auth(undefined)']?.data?.data;
        if (!isSuperAdmin(currentUser) || unionList?.length === 0) return;
        const unionsGroupedByMasterUnions = [];
        for (let i = 0; i < unionList.length; i++) {
          const masterUnion = unionList[i];
          const subUnions = [];
          for (let k = 0; k < unionList.length; k++) {
            const subUnion = unionList[k];
            if (masterUnion.id === subUnion.masterUnionId)
              subUnions.push(subUnion);
          }
          if (masterUnion.id === masterUnion.masterUnionId)
            unionsGroupedByMasterUnions.push({
              masterUnion,
              subUnions,
            });
        }
        const sorted = unionsGroupedByMasterUnions.sort((e1, e2) => e1 - e2);
        dispatch(selectUnionsGroupedByMasterUnions(sorted));
      })
      .catch((e) => {
        dispatch(fetchUnionListFail());
      });
  };
};

// master unions
export const fetchMasterUnionsStart = () => {
  return {
    type: actionTypes.FETCH_MASTER_UNIONS_START,
  };
};

export const fetchMasterUnionsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_MASTER_UNIONS_SUCCESS,
    unionList: data,
  };
};

export const fetchMasterUnionsFail = (error) => {
  return {
    type: actionTypes.FETCH_MASTER_UNIONS_FAIL,
    error: error,
  };
};

export const fetchMasterUnions = () => {
  return (dispatch) => {
    dispatch(fetchMasterUnionsStart());
    const url = `${process.env.REACT_APP_API_URL}/api/v1/MasterUnions/GetAllMasterUnions`;

    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(fetchMasterUnionsSuccess(r.data));
      })
      .catch((e) => {
        dispatch(fetchMasterUnionsFail());
      });
  };
};

export const deleteUnion = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/Union/DeleteUnion/${id}`;
    return axios
      .delete(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(deleteUnionSuccess());
        dispatch(
          setSnackbar({
            open: true,
            type: 'success',
            message: 'Union Deleted!',
          })
        );

        return r;
      })
      .catch((e) => {
        dispatch(deleteUnionFail());
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: 'There was an error.',
          })
        );

        return e;
      });
  };
};

export const deleteUnionFail = (error) => {
  return {
    type: actionTypes.DELETE_UNION_FAIL,
  };
};

export const deleteUnionSuccess = (data) => {
  return {
    type: actionTypes.DELETE_UNION_SUCCESS,
  };
};

// master union locations
export const fetchFederalLocationsStart = () => {
  return {
    type: actionTypes.FETCH_FEDERAL_LOCATIONS_START,
  };
};

export const fetchFederalLocationsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FEDERAL_LOCATIONS_SUCCESS,
    locations: data,
  };
};

export const fetchFederalLocationsFail = (error) => {
  return {
    type: actionTypes.FETCH_FEDERAL_LOCATIONS_FAIL,
    error: error,
  };
};

export const fetchFederalLocations = () => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/FederalState/GetFederalStates`;
    dispatch(fetchFederalLocationsStart());
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(fetchFederalLocationsSuccess(r.data));
      })
      .catch((e) => {
        dispatch(fetchFederalLocationsFail());
      });
  };
};

// master union state
export const fetchStateUnionsStart = () => {
  return {
    type: actionTypes.FETCH_STATE_UNIONS_START,
  };
};

export const fetchStateUnionsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_STATE_UNIONS_SUCCESS,
    stateUnions: data,
  };
};

export const fetchStateUnionsFail = (error) => {
  return {
    type: actionTypes.FETCH_STATE_UNIONS_FAIL,
    error: error,
  };
};

export const fetchStateUnions = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v2/unions/states/${id}`;
    dispatch(fetchStateUnionsStart());
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(fetchStateUnionsSuccess(r.data));
      })
      .catch((e) => {
        dispatch(fetchStateUnionsFail());
      });
  };
};

// Fetch District Unions
export const fetchDistrictUnionsStart = () => {
  return {
    type: actionTypes.FETCH_DISTRICT_UNIONS_START,
  };
};

export const fetchDistrictUnionsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_DISTRICT_UNIONS_SUCCESS,
    districtUnions: data,
  };
};

export const fetchDistrictUnionsFail = (error) => {
  return {
    type: actionTypes.FETCH_DISTRICT_UNIONS_FAIL,
    error: error,
  };
};

export const fetchDistrictUnions = () => {
  return (dispatch) => {
    dispatch(fetchDistrictUnionsStart());

    let url = `${process.env.REACT_APP_API_URL}/api/v1/Union/GetDistrictUnions`;

    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(fetchDistrictUnionsSuccess(response.data.data));
      })
      .catch((error) => {
        dispatch(fetchDistrictUnionsFail(error));
      });
  };
};

// master cross organizations
export const fetchCrossOrganizationsStart = () => {
  return {
    type: actionTypes.FETCH_CROSS_ORGANIZATIONS_START,
  };
};

export const fetchCrossOrganizationsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CROSS_ORGANIZATIONS_SUCCESS,
    organizations: data,
  };
};

export const fetchCrossOrganizationsFail = (error) => {
  return {
    type: actionTypes.FETCH_CROSS_ORGANIZATIONS_FAIL,
    error: error,
  };
};

export const fetchMasterCrossOrganizations = () => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/CrossSectionalOrganization/GetAllCrossSectionalOrganizations`;
    dispatch(fetchCrossOrganizationsStart());
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(fetchCrossOrganizationsSuccess(r.data));
      })
      .catch((e) => {
        dispatch(fetchCrossOrganizationsFail());
      });
  };
};

// Edit Union
export const editUnionStart = () => {
  return {
    type: actionTypes.EDIT_UNION_START,
  };
};

export const editUnionSuccess = () => {
  return {
    type: actionTypes.EDIT_UNION_SUCCESS,
  };
};

export const editUnionFail = (error) => {
  return {
    type: actionTypes.EDIT_UNION_FAIL,
    error: error,
  };
};

export const editUnion = (data) => {
  return (dispatch) => {
    dispatch(editUnionStart());

    let url = `${process.env.REACT_APP_API_URL}/api/v1/Union/UpdateUnion/${data.id}`;

    axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(editUnionSuccess());

        dispatch(
          setSnackbar({
            open: true,
            type: 'success',
            message: 'Union updated.',
          })
        );
      })
      .catch((error) => {
        dispatch(editUnionFail(error));
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: 'Error with updating union.',
          })
        );
      });
  };
};
export const editUnionEmail = (data) => {
  return (dispatch) => {
    dispatch(editUnionStart());

    let url = `${process.env.REACT_APP_API_URL}/api/v1/Union/UpdateUnionEmail/${data.unionId}`;

    axios
      .put(url, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(editUnionSuccess());

        dispatch(
          setSnackbar({
            open: true,
            type: 'success',
            message: 'Union updated.',
          })
        );
      })
      .catch((error) => {
        dispatch(editUnionFail(error));
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: 'Error with updating union.',
          })
        );
      });
  };
};

export const getUnionStart = () => {
  return {
    type: actionTypes.GET_UNION_START,
  };
};

export const getUnionSuccess = (data) => {
  return {
    type: actionTypes.GET_UNION_SUCCESS,
    union: data,
  };
};

export const getUnionFail = (error) => {
  return {
    type: actionTypes.GET_UNION_FAIL,
    error: error,
  };
};

export const getUnionRssStart = (data) => {
  return {
    type: actionTypes.GET_UNION_RSS_START,
    unionRssLink: data,
  };
};

export const getUnionRssSuccess = (data) => {
  return {
    type: actionTypes.GET_UNION_RSS_SUCCESS,
    unionRssLink: data,
  };
};

export const getUnionRssFail = (error) => {
  return {
    type: actionTypes.GET_UNION_RSS_FAIL,
    error: error,
  };
};

export const getUnionById = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v1/Union/GetUnionById/${id}`;

    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(getUnionSuccess(r.data));
      })
      .catch((e) => {
        dispatch(getUnionFail());
      });
  };
};

export const getUnionRssLink = (id) => {
  return (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/api/v2/unions/${id}/rss`;

    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((r) => {
        dispatch(getUnionRssSuccess(r.data));
      })
      .catch((e) => {
        dispatch(getUnionRssFail());
      });
  };
};

export const setUnionRssLinkStart = () => {
  return {
    type: actionTypes.PUT_UNION_RSS_START,
  };
};

export const setUnionRssLinkSuccess = () => {
  return {
    type: actionTypes.PUT_UNION_RSS_SUCCESS,
  };
};

export const setUnionRssLinkFail = (error) => {
  return {
    type: actionTypes.PUT_UNION_RSS_FAIL,
    error: error,
  };
};

export const setUnionRssLink = (rssurl, id) => {
  return (dispatch) => {
    dispatch(setUnionRssLinkStart());

    let url = `${process.env.REACT_APP_API_URL}/api/v2/unions/${id}/rss`;

    axios
      .put(url, {
        url: rssurl,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        dispatch(setUnionRssLinkSuccess());

        dispatch(
          setSnackbar({
            open: true,
            type: 'success',
            message: 'Union updated.',
          })
        );
      })
      .catch((error) => {
        dispatch(setUnionRssLinkFail(error));
        dispatch(
          setSnackbar({
            open: true,
            type: 'error',
            message: 'Error with updating union.',
          })
        );
      });
  };
};

// Get Unions by MasterUnionId
export const fetchUnionsByMasterUnionIdStart = () => {
  return {
    type: actionTypes.FETCH_UNIONS_BY_MASTER_UNION_ID_START,
  };
};

export const fetchUnionsByMasterUnionIdSuccess = (data) => {
  return {
    type: actionTypes.FETCH_UNIONS_BY_MASTER_UNION_ID_SUCCESS,
    unionsByMasterUnion: data,
  };
};

export const fetchUnionsByMasterUnionIdFail = (error) => {
  return {
    type: actionTypes.FETCH_UNIONS_BY_MASTER_UNION_ID_FAIL,
    error: error,
  };
};

export const fetchUnionsByMasterUnionId = (masterUnionId) => {
  return (dispatch) => {
    dispatch(fetchUnionsByMasterUnionIdStart());

    const url = `${process.env.REACT_APP_API_URL}/api/v1/Union/GetUnionsByMasterUnionId/${masterUnionId}`;

    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        dispatch(fetchUnionsByMasterUnionIdSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchUnionsByMasterUnionIdFail(error));
      });
  };
};

export const selectUnionSuccess = (data) => {
  return {
    type: actionTypes.SELECT_UNION_SUCCESS,
    selectedUnion: data,
  };
};

export const selectUnion = (union) => {
  return (dispatch) => {
    dispatch(selectUnionSuccess(union));
  };
};

export const selectUnionsGroupedByMasterUnionsSuccess = (data) => {
  return {
    type: actionTypes.SELECT_UNIONS_GROUPED_BY_MASTER_SUCCESS,
    allUnionsGroupedByMasterUnions: data,
  };
};

export const selectUnionsGroupedByMasterUnions = (
  allUnionsGroupedByMasterUnions
) => {
  return (dispatch) => {
    dispatch(
      selectUnionsGroupedByMasterUnionsSuccess(allUnionsGroupedByMasterUnions)
    );
  };
};

// Fetch Dropdown Unions
export const fetchDropdownUnionsStart = () => {
  return {
    type: actionTypes.FETCH_DROPDOWN_UNIONS_START,
  };
};

export const fetchDropdownUnionsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_DROPDOWN_UNIONS_SUCCESS,
    dropdownUnions: data,
  };
};

export const fetchDropdownUnionsFail = (error) => {
  return {
    type: actionTypes.FETCH_DROPDOWN_UNIONS_FAIL,
    error: error,
  };
};

export const fetchDropdownUnions = () => {
  return (dispatch) => {
    dispatch(fetchDropdownUnionsStart());

    const url = `${process.env.REACT_APP_API_URL}/api/v1/Union/GetUnionsForCockpitUser`;

    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        const dropdownUnions = response.data.data;
        dispatch(fetchDropdownUnionsSuccess(dropdownUnions));
        dispatch(selectUnion(dropdownUnions[0].id));
      })
      .catch((e) => {
        dispatch(fetchDropdownUnionsFail());
      });
  };
};
