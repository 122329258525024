import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as yup from 'yup';
import translation_de from '../locales/de/translate.json';
import yupLocaleDe from './yupDe';

i18next.use(initReactI18next).init({
  resources: {
    de: {
      translation: translation_de,
    },
  },
  lng: 'de',
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false,
  },
});

yup.setLocale(yupLocaleDe);

export default i18next;
