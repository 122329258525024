import { Button, Card, styled, Typography } from '@mui/material';
import Logo from 'assets/images/Cockpit_logo.png';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePostApiV1AccountConfirmEmailQuery } from 'store/api';

const StyledCont = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  gap: theme.spacing(6),
  padding: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingInline: theme.spacing(10),
  paddingBlock: theme.spacing(5),
  textAlign: 'center',
  maxWidth: theme.breakpoints.values.md,
}));

const ConfirmEmailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, isFetching } = usePostApiV1AccountConfirmEmailQuery(
    Object.fromEntries([...new URLSearchParams(useLocation().search).entries()])
  );

  const isError = data?.succeded === false;

  if (isFetching) return null;

  return (
    <StyledCont>
      <img src={Logo} alt="Logo" height={81} width={195} />
      <StyledCard>
        <Typography color="primary" fontWeight="bold" variant="caption">
          {!isError
            ? t('ConfirmEmail.SuccessTitle')
            : t('ConfirmEmail.ErrorTitle')}
        </Typography>
        {!isError && (
          <Typography variant="subtitle1">
            {t('ConfirmEmail.SuccessSubtitle')}
          </Typography>
        )}
        <Button onClick={() => navigate('/')}>
          {t('ConfirmEmail.ToHome')}
        </Button>
      </StyledCard>
    </StyledCont>
  );
};

export default ConfirmEmailPage;
