const ROUTES = {
  HOME: {
    NAME: 'Home',
    PATH: '/',
  },
  LOGIN: {
    NAME: 'Login',
    PATH: '/login',
  },
  CREATE_NEW_USER: {
    NAME: 'CreateNewUser',
    PATH: '/create-new-user',
  },
  CREATE_NEW_UNION: {
    NAME: 'CreateNewUnion',
    PATH: '/create-new-union',
  },
  COCKPIT_USERS: {
    NAME: 'CockpitUsers',
    PATH: '/cockpit-users',
  },
  COCKPIT_USER_DETAILS: {
    NAME: 'CockpitUserDetails',
    PATH: '/cockpit-users/:userid',
  },
  COCKPIT_USER_PERMISSIONS: {
    NAME: 'CockpitUserPermissions',
    PATH: '/cockpit-users/permissions/:userid',
  },
  UNION_LIST: {
    NAME: 'UnionList',
    PATH: '/union-list',
  },
  CHECK_EMAIL: {
    NAME: 'CheckEmail',
    PATH: '/check-email',
  },
  CONFIRM_EMAIL: {
    NAME: 'ConfirmEmail',
    PATH: '/confirmemail',
  },
  CONTENT_WIZARD: {
    NAME: 'ContentWizard',
    PATH: '/content-wizard',
  },
  CONTENTS: {
    NAME: 'Contents',
    PATH: '/contents',
  },
  CONTENTS_TOPICS: {
    NAME: 'ContentsTopics',
    PATH: '/contents/topics',
  },
  CONTENTS_NEWS: {
    NAME: 'ContentsNews',
    PATH: '/contents/news',
  },
  CONTENTS_RSS: {
    NAME: 'ContentsRSS',
    PATH: '/contents/rss-feed',
  },
  CONTENTS_DISRUPTOR: {
    NAME: 'Disruptor',
    PATH: '/contents/disruptor',
  },
  CONTENTS_ADS: {
    NAME: 'Ads',
    PATH: '/contents/ads',
  },
  CONTENT_DETAILS: {
    NAME: 'ContentDetails',
    PATH: '/contents/:contentId',
  },
  CONTENT_EDIT: {
    NAME: 'ContentEdit',
    PATH: '/contents/edit/:contentId',
  },
  VALIDATE_CODE: {
    NAME: 'ValidateCode',
    PATH: '/validatecode',
  },
  UNION_RSS: {
    NAME: 'UnionRss',
    PATH: '/union-settings/rss-feed',
  },
  UNION_EMAILS: {
    NAME: 'UnionEmails',
    PATH: '/union-settings/emails',
  },
  UNION_FOOTER: {
    NAME: 'UnionUnionFooterSocials',
    PATH: '/union-settings/footer',
  },
  CONTACT_MESSAGES: {
    NAME: 'ContactMessages',
    PATH: '/union-settings/contact-messages',
  },
  CONTACT_MESSAGE: {
    NAME: 'ContactMessage',
    PATH: '/union-settings/contact-messages/:id',
  },
  CREATE_CONTACT: {
    NAME: 'CreateContact',
    PATH: '/union-settings/create-contact',
  },
  COMMITTEES: {
    NAME: 'Committees',
    PATH: '/union-settings/committees',
  },
  CREATE_COMMITTEE: {
    NAME: 'CreateCommittee',
    PATH: '/union-settings/create-committee',
  },
  EDIT_COMMITTEE: {
    NAME: 'EDITCommittee',
    PATH: '/union-settings/committees/:id',
  },
};

export default ROUTES;
