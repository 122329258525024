// TODO: Used for edit too... Should refactor.
import { yupResolver } from '@hookform/resolvers/yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Box, Button, Card, MenuItem, Select, TextField } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import AccessControl from 'components/Shared/accessControl';
import { Avatar } from 'elements/Avatar';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { setSnackbar } from 'store/actions/notificationActions';
import {
  useGetApiV1CommitteeMemberByIdQuery,
  useGetApiV1UnionMemberCategoriesQuery,
  useGetApiV2TitlesQuery,
  usePostApiV1CreateCommitteesMemberMutation,
  usePutApiV2CommitteesMemberMutation,
} from 'store/api';
import { number, object, string } from 'yup';
import saveIcon from '../../assets/images/save_icon.svg';
import { UploadImageDialog } from '../../components/Contacts/UploadImageDialog';

const CreateCommitteeShell = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingInline: '50px',
  paddingBlock: '56px',
}));

const CreateCommitteeCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  padding: '2rem',
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
}));

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
}));

export const CreateCommittee = () => {
  const unionId = useSelector((state) => state.unions.selectedUnion);
  let { id } = useParams();

  if (!unionId) return null;
  return <CreateCommitteeBase unionId={unionId} id={id} />;
};

export const CreateCommitteeBase = ({ unionId, id }) => {
  const { data: categories, isFetching: isFetchingData } =
    useGetApiV1UnionMemberCategoriesQuery();

  const { data: personData, isFetching: isFetchingPersonData } =
    useGetApiV1CommitteeMemberByIdQuery({ id });

  if (isFetchingData || isFetchingPersonData) return null;

  return <CreateCommitteeForm {...{ unionId, categories, personData, id }} />;
};

function CreateCommitteeForm({ unionId, categories, personData, id }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contactTitles = useGetApiV2TitlesQuery();
  const [createCommittee] = usePostApiV1CreateCommitteesMemberMutation();
  const [editCommittee] = usePutApiV2CommitteesMemberMutation();

  const phoneRegex = /^[+\d]?(?:[\d]*)$/;

  const schema = object().shape({
    firstName: string().required(),
    lastName: string().required(),
    position: string().required(),
    category: number().required(),
    phone: string().matches(phoneRegex).notRequired().nullable(),
    email: string().notRequired().email().nullable(),
  });

  const [image, setImage] = useState(
    personData
      ? {
          photoMedia: personData.photoMedia,
          ...personData.photo,
        }
      : {}
  );

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const defaultValues = {
    title: personData?.unionBoardMemberTitleId || 1,
    firstName: personData ? personData.firstName : '',
    lastName: personData ? personData.lastName : '',
    position: personData ? personData.position : '',
    category: personData ? personData.unionMemberCategoryId : '',
    phone: personData ? personData.phoneNo : '',
    email: personData ? personData.email : '',
  };

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onChangeSeo = (event, type) => {
    switch (type) {
      case 'alternateText':
        setImage({ ...image, alternateText: event.target.value });
        break;
      case 'title':
        setImage({ ...image, title: event.target.value });
        break;
      case 'imageSource':
        setImage({ ...image, imageSource: event.target.value });
        break;
      case 'description':
        setImage({ ...image, description: event.target.value });
        break;
      default:
        return;
    }
  };

  const onSubmit = async (data) => {
    const values = {
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      position: data.position,
      unionMemberCategoryId: +data.category,
      phoneNo: data.phone,
      email: data.email,
      photo:
        JSON.stringify(image) === '{}' ||
        (Object.keys(image).length === 1 && image.photoMedia === '')
          ? null
          : {
              ...image,
              fileData: image.fileData ? image.fileData : personData.photoMedia,
            },
      unionBoardMemberTitleId: data.title,
      unionId: unionId,
    };

    if (id) {
      editCommittee({ data: { ...values, id: id } });
    } else {
      createCommittee({ data: values });
    }

    dispatch(
      setSnackbar({
        open: true,
        type: 'success',
        message: id
          ? t('committees.messages.editSuccess')
          : t('committees.messages.createSuccess'),
      })
    );
    navigate(routes.COMMITTEES);
  };

  const { enqueueSnackbar } = useSnackbar();

  if (contactTitles.isLoading) return null;

  return (
    <CreateCommitteeShell>
      <UploadImageDialog
        open={openDialog}
        onClose={handleClose}
        onImageUpload={(base64, file) => {
          const fileData = base64;
          const [name] = file.name.split('.');
          setImage({
            mediaFileTypeId: 1,
            fileData,
            name,
            fileExtension: 'jpeg',
            alternateText: '',
            title: '',
            imageSource: '',
            description: '',
          });
          enqueueSnackbar(t('Common.ImageSuccess'), { variant: 'success' });
        }}
        image={image}
        onChangeSeo={onChangeSeo}
      />
      <Box
        sx={{ pt: '0', pb: '56px', width: '100%' }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1>{t('Unions.Committees')}</h1>
        <h2>{t('Menu.DetailedView')}</h2>
        <CreateCommitteeCard>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '75%',
              mb: '30px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Wizard.Title')}</h3>
                <Controller
                  name={'title'}
                  control={control}
                  {...register('title', { required: true })}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      fullWidth
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {contactTitles.data.map(({ id, name }) => (
                        <MenuItem value={id} key={id} children={name} />
                      ))}
                    </StyledSelect>
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 2,
                  width: '100%',
                  mr: '30px',
                  gap: '30px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    width: '100%',
                  }}
                >
                  <h3>{t('Users.FirstName')}</h3>
                  <Controller
                    name={'firstName'}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder={t('Users.FirstName')}
                        error={!!errors['firstName']}
                        helperText={errors['firstName']?.message}
                      />
                    )}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    width: '100%',
                  }}
                >
                  <h3>{t('Users.LastName')}</h3>
                  <Controller
                    name={'lastName'}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder={t('Users.LastName')}
                        error={!!errors['lastName']}
                        helperText={errors['lastName']?.message}
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: '30px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Unions.LocationTitle')}</h3>
                <Controller
                  name={'position'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Unions.LocationTitle')}
                      error={!!errors['position']}
                      helperText={errors['position']?.message}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 2,
                  flexDirection: 'column',
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Unions.CommitteeCategory')}</h3>
                <Controller
                  name={'category'}
                  control={control}
                  {...register('category', { required: true })}
                  render={({ field }) => (
                    <StyledSelect
                      {...field}
                      fullWidth
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {categories?.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Users.PhoneNumber')}</h3>
                <Controller
                  name={'phone'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Users.PhoneNumber')}
                      error={!!errors['phone']}
                      helperText={
                        errors['phone']?.type === 'matches'
                          ? t('Errors.WrongPhoneFormat')
                          : errors['phone']?.message
                      }
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flex: 2,
                  flexDirection: 'column',
                  width: '100%',
                  mr: '30px',
                }}
              >
                <h3>{t('Login.EmailLabel')}</h3>
                <Controller
                  name={'email'}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t('Login.EmailLabel')}
                      error={!!errors['email']}
                      helperText={errors['email']?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '25%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <h3>{t('Contacts.ProfilePicture')}</h3>
                <LightTooltip title={t('Content.ImageSizeInfo')}>
                  <HelpOutlineIcon
                    sx={{
                      color: theme.palette.grey[900],
                      cursor: 'pointer',
                      ml: '10px',
                    }}
                  />
                </LightTooltip>
              </Box>
              <Avatar
                photo={image.fileData || image?.photoMedia}
                onDelete={() => setImage({})}
              />
            </Box>
            <Box sx={{ display: 'flex', width: '100%' }}>
              <AccessControl
                permissionsName={['Einstellungen']}
                allowedPermissions={['edit']}
              >
                <Button
                  style={{
                    minHeight: '57px',
                    marginBottom: '4px',
                    width: '100%',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                  size="large"
                  variant="contained"
                  startIcon={<PhotoCamera />}
                  onClick={() => setOpenDialog(true)}
                >
                  {t('Contacts.ChangeImage')}
                </Button>
              </AccessControl>
            </Box>
          </Box>
        </CreateCommitteeCard>
        <AccessControl
          permissionsName={['Einstellungen']}
          allowedPermissions={['edit']}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '2rem',
            }}
          >
            <Button
              sx={{
                minHeight: '60px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
              size="large"
              variant="contained"
              type="button"
              color="secondary"
              onClick={() => navigate(-1)}
            >
              {t(`Common.Back`)}
            </Button>

            <Button
              sx={{
                minHeight: '60px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
              }}
              size="large"
              variant="contained"
              type="submit"
            >
              <img src={saveIcon} alt="save" style={{ marginRight: '20px' }} />
              {t(`Common.SaveChanges`)}
            </Button>
          </div>
        </AccessControl>
      </Box>
    </CreateCommitteeShell>
  );
}
