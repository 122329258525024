import React from 'react';

export const Login = React.lazy(() => {
  return import('../containers/Login/Login');
});

export const Home = React.lazy(() => {
  return import('../containers/Home/Home');
});

export const NotFound = React.lazy(() => {
  return import('../containers/NotFound/NotFound');
});

export const CreateNewUser = React.lazy(() => {
  return import('../containers/CreateNewUser/CreateNewUser');
});

export const CockpitUsers = React.lazy(() => {
  return import('../containers/CockpitUsers/CockpitUsers');
});

export const CockpitUserDetails = React.lazy(() => {
  return import('../containers/CockpitUsers/CockpitUserDetails');
});

export const CockpitUserPermissions = React.lazy(() => {
  return import('../containers/CockpitUsers/CockpitUserPermissions');
});

export const CreateNewUnion = React.lazy(() => {
  return import('../containers/CreateNewUnion');
});

export const UnionList = React.lazy(() => {
  return import('../containers/UnionList');
});

export const CheckEmail = React.lazy(() => {
  return import('../containers/CheckEmailPage/CheckEmailPage');
});

export const ConfirmEmail = React.lazy(() => {
  return import('../containers/ConfirmEmailPage/ConfirmEmailPage');
});

export const ContentWizard = React.lazy(() => {
  return import('../containers/ContentWizard/ContentWizard');
});

export const Contents = React.lazy(() => {
  return import('../containers/Contents/Contents');
});

export const ContentDetails = React.lazy(() => {
  return import('../containers/Contents/ContentDetails');
});

export const ContentEdit = React.lazy(() => {
  return import('../containers/Contents/ContentEdit');
});

export const ValidateCodePage = React.lazy(() => {
  return import('../containers/ValidateCodePage/ValidateCodePage');
});

export const UnionEmails = React.lazy(() => {
  return import('../containers/UnionSettings/UnionEmails');
});

export const UnionRss = React.lazy(() => {
  return import('../containers/UnionSettings/UnionRss');
});

export const ContactMessage = React.lazy(() => {
  return import('../containers/Contacts/ContactMesssage');
});
