import * as actionTypes from './actionTypes';

export const setSnackbar = (data) => {
  return {
    type: actionTypes.SET_SNACKBAR,
    snackbar: data,
  };
};

export const setCheckEmail = (data) => {
  return {
    type: actionTypes.SET_CHECK_EMAIL,
    email: data,
  };
};
