export function arrayBufferToBase64(arrayBuffer: ArrayBuffer, type: string) {
  return new Promise<string>((resolve) => {
    const blob = new Blob([arrayBuffer], { type });
    const reader = new FileReader();
    reader.onload = function (event) {
      const base64 = event.target?.result as string;
      resolve(base64);
    };
    reader.readAsDataURL(blob);
  });
}
